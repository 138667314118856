import React, { FC } from 'react';
import ForwardIcon from '../../assets/icons/forward';
import styles from './help.module.scss';
import { useDispatchContext } from '../../AppProvider';

interface HelpProps {
  setIsTourOpen: any;
  setFiltersVisibility: any;
  isMobile: any;
}

const Help: FC<HelpProps> = ({ isMobile, setFiltersVisibility, setIsTourOpen }) => {
  const dispatch = useDispatchContext();
  
  let handleTourOpen = () => {
    if (isMobile) {
      setFiltersVisibility(true)
    }
    // setDrawerOpen(false)
    dispatch({ type: 'SET_DRAWER_OPEN', payload: { open: false, drawerView: '' } });
    setIsTourOpen(true)
  }

  return (
    <div className={styles.Help}>
      <h2>Help</h2>
      <hr />
      <h3>Take an In-App Tour</h3>
      <p>Take a brief tour of the Portfolio Manager Data Explorer to learn about its features and how to use them.</p>
      <img src={require('../../assets/images/help-tour.jpg')} className={styles.HelpTourIMG} alt="" />
      <button onClick={() => handleTourOpen()} className="usa-button usa-button-white use-button-teal">Let's Go!</button>
      <hr />
      <h3>Learn More about This Tool</h3>
      <a href="http://www.energystar.gov/buildings/resources_topic/energy_star_portfolio_manager_data_explorer" target="_blank" rel="noopener noreferrer">Visit the Portfolio Manager Data Explorer About page <ForwardIcon scale={.5} color="#fff" /></a>
      <hr />
      <h3>Learn More about Portfolio Manager</h3>
      <p>Portfolio Manager is an interactive resource management tool that enables you to benchmark the energy use of any type of building, brought to you by EPA and ENERGY STAR. Visit our website to learn more.</p>
      <a href="https://www.energystar.gov/buildings/benchmark" target="_blank" rel="noopener noreferrer">Visit the Portfolio Manager site <ForwardIcon scale={.5} color="#fff" /></a>
    </div>
  )

};

export default Help;
