import { Tooltip } from "@mui/material";
import React, { FC } from "react";
import GlossaryIcon from "../../assets/icons/glossary";
import styles from "./checkboxList.module.scss";

interface CheckboxListProps {
  data: any;
  label: any;
  dataGroup: any;
  setDataGroup: any;
  id: any;
  subHeading: any;
  glossaryLink: any;
}

const CheckboxList: FC<CheckboxListProps> = ({
  glossaryLink,
  subHeading,
  id,
  dataGroup,
  setDataGroup,
  data,
  label,
}) => {
  const checkboxListIsClear = dataGroup.length < 1;

  let handleCheckboxListChange = (e: any) => {
    let updatedDataGroups = dataGroup ? [...dataGroup] : [];
    if (e.target.checked) {
      updatedDataGroups = [...updatedDataGroups, e.target.value];
    } else if (!e.target.checked) {
      updatedDataGroups = updatedDataGroups.filter((x) => x !== e.target.value);
    }
    if (setDataGroup) {
      setDataGroup(updatedDataGroups);
    }
  };

  let clearCheckboxListSelection = () => {
    if (setDataGroup) {
      setDataGroup([]);
    }
  };

  return (
    <fieldset>
      <span className="usa-legend grid-row flex-justify">
        <legend>
          {label}{" "}
          {subHeading ? <p className={styles.subHeading}>{subHeading}</p> : ""}
        </legend>
        {glossaryLink ? (
          <Tooltip title="Glossary (opens in new tab)">
            <a
              href={glossaryLink}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`${label} Glossary`}
              className={`usa-button usa-button--unstyled iconButton`}
            >
              <GlossaryIcon scale={1} color="#182e4e" />
            </a>
          </Tooltip>
        ) : (
          ""
        )}
      </span>

      {data.map((object: any) => (
        <div className="usa-checkbox" key={object.value}>
          <input
            className="usa-checkbox__input"
            id={object.id}
            key={object.value}
            type="checkbox"
            name={id}
            value={object.value}
            checked={dataGroup.includes(object.value)}
            onChange={(e) => handleCheckboxListChange(e)}
          />
          <label className="usa-checkbox__label" htmlFor={object.id}>
            {object.label}
          </label>
        </div>
      ))}

      <div className="grid-row flex-justify-end">
        <button
          id={`${id}-clear`}
          className="usa-button--unstyled clear-button"
          disabled={checkboxListIsClear}
          onClick={(name) => clearCheckboxListSelection()}
        >
          Clear selection
        </button>
      </div>
    </fieldset>
  );
};

export default CheckboxList;
