import { gql } from "@apollo/client";

// NOTE: changes made to the query need to be run through npm run codemon or the codemon must be watching for changes
export const GET_FILTERS  = gql`
    query GetFilters {
      getPropertyTypeCategories {
        ptCategory
        ptSubcategory
      }
      getClimateZones {
        climateZone
      }
      getCsa {
        csa_city
        csa_area
      }
    }
  `;