import { Tooltip } from "@mui/material";
import React, {
  FC,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import GlossaryIcon from "../../assets/icons/glossary";
import styles from "./multiSelectDropdown.module.scss";
import { useAppContext } from "../../AppProvider";

interface MultiSelectDropdownProps {
  label: any;
  data: any;
  name: any;
  setGroup1: any;
  setGroup2: any;
  group1: any;
  group2: any;
  glossaryLink: any;
}

// only applies to "Not in a CBSA (rural)"
const modifyDataStructure = (data: any) => {
  const modifiedData = data.slice(0).map((item: any) => {
    if (item.children && item.value === "Not in a CBSA (rural)") {
      return { ...item, children: [] };
    }
    return item;
  });
  return modifiedData;
};

const MultiSelectDropdown: FC<MultiSelectDropdownProps> = ({
  glossaryLink,
  setGroup1,
  setGroup2,
  group1,
  group2,
  label,
  data,
  name,
}) => {
  const { resetFiltersToggle } = useAppContext();
  const [selectedChildrenCount, setSelectedChildrenCount] = useState(0);
  const [multiSelectIsClear, setMultiSelectIsClear] = useState(true);

  const modifiedData = modifyDataStructure(data);

  const dropdownRef = useRef(null);

  useEffect(() => {
    handleTabIndexes();
    const multiSelectInput = document.querySelector(
      `.${name} .dropdown .dropdown-trigger .tag-list .tag-item input`
    ) as any;
    multiSelectInput.type = "search";
  });

  const handleMultiSelectChange = 
    (e: any, selected: any) => {
      let updatedSelectedChildrenCount = selectedChildrenCount;
      // Update count
      if (e._depth === 1) {
        if (e.checked) {
          updatedSelectedChildrenCount += 1;
        } else {
          updatedSelectedChildrenCount -= 1;
        }
      } else if (e._depth === 0) {
        const children = e._children.length;
        // only applies to "Not in a CBSA (rural)"
        if (children === 0) {
          if (e.checked) {
            updatedSelectedChildrenCount += 1;
          } else {
            updatedSelectedChildrenCount -= 1;
          }
          // end "Not in a CBSA (rural)" logic
        } else {
          if (e.checked) {
            updatedSelectedChildrenCount += children;
          } else {
            updatedSelectedChildrenCount -= children;
          }
        }
      }
      setSelectedChildrenCount(updatedSelectedChildrenCount);

      // Update table data based on group selection
      if (e.depth === 0 && e._children.length === 0) {
        setGroup1(
          selected.filter((x: any) => x._depth === 0).map((x: any) => x.value)
        );
        setGroup2(
          selected.filter((x: any) => x._depth === 0).map((x: any) => x.value)
        );
      } else {
        if (setGroup1 && setGroup2) {
          setGroup1(
            selected.filter((x: any) => x._depth === 1).map((x: any) => x.value)
          );
          setGroup2(
            selected.filter((x: any) => x._depth === 0).map((x: any) => x.value)
          );
        }
      }
    };

  let handleTabIndexes = () => {
    let multiSelectDropdownContent = document.getElementsByClassName(
      "dropdown-content"
    ) as any;
    let MSDCCheckboxes = document.getElementsByClassName(
      "checkbox-item"
    ) as any;
    let MSDCToggles = document.getElementsByClassName("toggle") as any;
    let searchInput = document.getElementsByClassName("search") as any;
    let searchTrigger = document.getElementById("rdts1_trigger") as any;
    Array.from(multiSelectDropdownContent).forEach((e: any) => {
      e.tabIndex = 0;
      e.ariaLabel = searchInput.placeholder + " checkbox tree";
    });
    searchInput.tabIndex = 0;
    searchTrigger.tabIndex = -1;
    setTimeout(function () {
      Array.from(MSDCCheckboxes).forEach((e: any) => {
        e.tabIndex = 0;
      });
    }, 5);
    Array.from(MSDCToggles).forEach((e: any) => {
      e.tabIndex = 0;
      e.ariaHidden = false;
      e.ariaLabel = "toggle checkbox group";
    });
  };

  let multiSelectDropDown = useMemo(() => {
    setSelectedChildrenCount(0);
    return (
      <DropdownTreeSelect
        onNodeToggle={handleTabIndexes}
        onChange={handleMultiSelectChange}
        showDropdown="always"
        keepTreeOnSearch={true}
        texts={{
          placeholder: `Search or select below`,
          label: `Search ${label}`,
        }}
        showPartiallySelected={true}
        data={modifiedData}
        key={data.label}
        className={`multiSelectDropdown treeSelect step--${name} ${name}`}
      />
    );
    // eslint-disable-next-line
  }, [multiSelectIsClear, resetFiltersToggle]);

  let clearMultiSelect = () => {
      setMultiSelectIsClear(!multiSelectIsClear);
      setGroup1(undefined);
      setGroup2(undefined);
    };

  return (
    <div className={styles.MultiSelectDropdown} ref={dropdownRef}>
      <span className="usa-label grid-row flex-justify">
        <label id={`${name}-ts-label`} htmlFor={name}>
          {label}
        </label>
        {glossaryLink ? (
          <Tooltip title="Glossary (opens in new tab)">
            <a
              href={glossaryLink}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`${label} Glossary`}
              className={`usa-button usa-button--unstyled iconButton`}
            >
              <GlossaryIcon scale={1} color="#182e4e" />
            </a>
          </Tooltip>
        ) : (
          ""
        )}
      </span>
      <div>
        {multiSelectDropDown}
      </div>
      <div className="grid-row flex-justify-end clear-button">
        <button
          id={`${label}-clear`}
          className={`usa-button--unstyled step--clear-${name}`}
          disabled={ selectedChildrenCount === 0 }
          onClick={() => clearMultiSelect()}
        >
          Clear selection
        </button>
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
