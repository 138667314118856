import React, { FC, useEffect, useState } from "react";
import styles from "./filterSidepanel.module.scss";
import {
  chartOptions,
  energyStarCertified,
  energyUseIntensity,
  grossFloorArea,
  tableColumns,
  yearBuilt,
  yearReportedRadios,
  // workerDensity,
  weeklyHours,
  stateFilters,
} from "../../assets/data/filters";
import InfoIcon from "../../assets/icons/info";
import MultiSelectDropdown from "../multiSelectDropdown/multiSelectDropdown";
import CheckboxList from "../checkboxList/checkboxList";
import { LinearProgress, Tooltip } from "@mui/material";
import GlossaryIcon from "../../assets/icons/glossary";
import SingleSelectDropdown from "../singleSelectDropdown/singleSelectDropdown";
import {
  useAppContext,
  useDispatchContext,
  useFiltersQuery,
} from "../../AppProvider";
import { GetFiltersQuery } from "../../graphql/generated/graphql";

interface FilterSidepanelProps {
  view: any;
  setView: any;
  setChartType: any;
  handleColumnChange: any;
}

const FilterSidepanel: FC<FilterSidepanelProps> = ({
  view,
  setView,
  setChartType,
  handleColumnChange,
}) => {
  const {
    isTrendsShown,
    withMean,
    withMedian,
    withFifthPercentile,
    withTwentyFifthPercentile,
    withSeventyFifthPercentile,
    withNinetyFifthPercentile,
    isEnergyStarCertified,
    yearReported,
    yearBuiltGroups,
    stateProvinceNames,
    gfaGroups,
    gfaGroup2s,
    weeklyHoursGroup,
    ptCategories,
    ptSubcategories,
    csa_area,
    csa_city,
    climateZone,
    location,
  } = useAppContext();

  const dispatch = useDispatchContext();

  const {
    data: filtersData,
    loading: filtersLoading,
    error: filtersError,
  } = useFiltersQuery<GetFiltersQuery>();

  const [isTablet, setIsTablet] = useState(
    window.innerWidth < 1190 && window.innerWidth > 640
  );
  // const [propertyType, setPropertyType] = useState([]);
  let ptFilterGroup: any = [];
  // const [csaFilters, setCsaFilters] = useState([]);
  let csaFilterGroup: any = [];
  // const [climateZoneFilters, setClimateZoneFilters] = useState([]);
  let climateZoneFilterGroup: any = [];

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const istablet = window.innerWidth < 1190 && window.innerWidth > 640;
        if (istablet !== isTablet) setIsTablet(istablet);
      },
      false
    );
  }, [isTablet]);

  if (filtersData && filtersData.getPropertyTypeCategories) {
    filtersData.getPropertyTypeCategories.forEach((e: any) => {
      const itemIndex = ptFilterGroup.findIndex(
        (d: any) => d.label === e.ptCategory
      );
      if (itemIndex === -1) {
        ptFilterGroup.push({
          label: e.ptCategory,
          value: e.ptCategory,
          children: [{ label: e.ptSubcategory, value: e.ptSubcategory }],
        });
      } else {
        ptFilterGroup[itemIndex].children.push({
          label: e.ptSubcategory,
          value: e.ptSubcategory,
        });
      }
    });
  }

  if (filtersData && filtersData.getClimateZones) {
    filtersData.getClimateZones.forEach((e: any) => {
      climateZoneFilterGroup.push({
        label: e.climateZone,
        value: e.climateZone,
        checked: false,
      });
    });
  }

  if (filtersData && filtersData.getCsa) {
    filtersData.getCsa.forEach((e: any) => {
      const itemIndex = csaFilterGroup.findIndex(
        (d: any) => d.label === e.csa_area
      );
      if (itemIndex === -1) {
        csaFilterGroup.push({
          label: e.csa_area,
          value: e.csa_area,
          children: [{ label: e.csa_city, value: e.csa_city }],
        });
      } else {
        csaFilterGroup[itemIndex].children.push({
          label: e.csa_city,
          value: e.csa_city,
        });
      }
    });
  }

  let handleChartView = (view: any, chartType: any) => {
    setView(view);
    dispatch({ type: "SET_THEN_BY", payload: undefined });
    setChartType(chartType);
    if (chartType === "Box-and-Whisker Plots") {
      dispatch({ type: "SET_CHART_TYPE_PLOT" });
    } else if (chartType === "Bar Charts") {
      dispatch({ type: "SET_CHART_TYPE_BAR" });
    } else if (chartType === "Trends") {
      dispatch({ type: "SET_CHART_TYPE_TRENDS" });
    }
  };

  let handleTableView = (view: any) => {
    setView(view);
    setChartType(undefined);
    dispatch({ type: "SET_IS_TRENDS_SHOWN", payload: false });
  };

  let handleLocationChange = (e: any) => {
    dispatch({ type: "SET_LOCATION", payload: e.target.value });

    // reset location state
    dispatch({ type: "SET_CLIMATE_ZONE", payload: undefined });
    dispatch({ type: "SET_CSA_AREA", payload: undefined });
    dispatch({ type: "SET_CSA_CITY", payload: undefined });
    dispatch({ type: "SET_STATE_PROVINCE_NAMES", payload: undefined });
  };

  if (filtersLoading) return <LinearProgress />;
  if (filtersError) return <p>Error: {filtersError.message}</p>;

  return (
    <div className={styles.FilterSidepanel} data-testid="FilterSidepanel">
      <div className="step--show-me">
        <h3>Show Me</h3>
        <fieldset>
          <legend aria-hidden="false" className="display-none">
            Select metric
          </legend>
          <span className="usa-legend grid-row flex-justify">
            <p>Energy Use Intensity (kBtu/sq. ft.)</p>
            <Tooltip title="Glossary (opens in new tab)">
              <a
                href="https://portfoliomanager.energystar.gov/pm/glossary#EUI"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Energy Use Intensity Glossary"
                className={`usa-button usa-button--unstyled iconButton`}
              >
                <GlossaryIcon scale={1} color="#182e4e" />
              </a>
            </Tooltip>
          </span>

          {energyUseIntensity.map(
            (object) =>
              object.type === "EUI" && (
                <div className="usa-radio" key={object.value}>
                  <input
                    className="usa-radio__input"
                    id={object.id}
                    key={object.value}
                    type="radio"
                    name="energy-use-intensity"
                    value={object.value}
                    defaultChecked={object.defaultChecked}
                    onChange={() => {
                      dispatch({ type: "SET_METRIC", payload: object.name });
                    }}
                  />
                  <label className="usa-radio__label" htmlFor={object.id}>
                    {" "}
                    <span className="grid-row flex-justify">
                      <div>{object.value}</div>
                      {object.glossaryLink ? (
                        <Tooltip title="Glossary (opens in new tab)">
                          <a
                            href={object.glossaryLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={`${object.value} Glossary`}
                            className={`usa-button usa-button--unstyled iconButton margin-left-1 iconButton`}
                          >
                            <GlossaryIcon scale={1} color="#182e4e" />
                          </a>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </span>
                  </label>
                </div>
              )
          )}

          <span className="usa-legend grid-row flex-justify">
            <p>Other Metrics</p>
          </span>
          {energyUseIntensity.map(
            (object) =>
              object.type === "other" && (
                <div className="usa-radio" key={object.value}>
                  <input
                    className="usa-radio__input"
                    id={object.id}
                    key={object.value}
                    type="radio"
                    name="energy-use-intensity"
                    value={object.value}
                    defaultChecked={object.defaultChecked}
                    onChange={() =>
                      dispatch({ type: "SET_METRIC", payload: object.name })
                    }
                  />
                  <label className="usa-radio__label" htmlFor={object.id}>
                    {" "}
                    <span className="grid-row flex-justify">
                      <div>{object.value}</div>
                      {object.glossaryLink ? (
                        <Tooltip title="Glossary (opens in new tab)">
                          <a
                            href={object.glossaryLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={`${object.value} Glossary`}
                            className={`usa-button usa-button--unstyled iconButton margin-left-1 iconButton`}
                          >
                            <GlossaryIcon scale={1} color="#182e4e" />
                          </a>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </span>
                  </label>
                </div>
              )
          )}
        </fieldset>
      </div>
      <hr />
      <div className="step--viewed-as">
        <h3>Viewed as</h3>
        <ul
          className={`usa-button-group viewed-as-button-group ${
            isTablet ? "" : "usa-button-group--segmented"
          }`}
        >
          <li className="usa-button-group__item">
            <button
              type="button"
              onClick={() => handleTableView("Table")}
              id="step--viewed-as-table"
              className={`usa-button ${
                view === "Table" ? "usa-button--active" : "usa-button--outline"
              }`}
            >
              Table
            </button>
          </li>
          <li className="usa-button-group__item">
            <button
              type="button"
              onClick={() => handleChartView("Chart", "Bar Charts")}
              id="step--viewed-as-chart"
              className={`usa-button ${
                view === "Chart" ? "usa-button--active" : "usa-button--outline"
              }`}
            >
              Chart
            </button>
          </li>
        </ul>
      </div>
      {view === "Table" ? (
        <fieldset className="usa-fieldset" id="table-columns-checkboxes">
          <label
            className="usa-legend EUI-disclaimer"
            htmlFor="table-columns-checkboxes"
          >
            Select which table columns you would like to see
          </label>
          {tableColumns.map((object, index) => {
            let checked = false;
            switch (object.value) {
              case "Mean":
                checked = withMean;
                break;
              case "Median":
                checked = withMedian;
                break;
              case "5th Percentile":
                checked = withFifthPercentile;
                break;
              case "25th Percentile":
                checked = withTwentyFifthPercentile;
                break;
              case "75th Percentile":
                checked = withSeventyFifthPercentile;
                break;
              case "95th Percentile":
                checked = withNinetyFifthPercentile;
                break;
              default:
                break;
            }

            return (
              <div className="usa-checkbox" key={object.value}>
                <input
                  className="usa-checkbox__input"
                  key={`k-${index}`}
                  id={object.id}
                  type="checkbox"
                  name="table-columns-checkboxes"
                  value={object.value}
                  onChange={handleColumnChange}
                  checked={checked}
                />
                <label className="usa-checkbox__label" htmlFor={object.id}>
                  {object.value}
                </label>
              </div>
            );
          })}
        </fieldset>
      ) : view === "Chart" ? (
        <fieldset>
          <label
            htmlFor="chart-options"
            aria-hidden="false"
            className="display-none"
          >
            Chart Options
          </label>
          {chartOptions
            .filter(
              (opt) =>
                !(
                  opt.id === "chart-options-trends" &&
                  process.env.REACT_APP_V220_FEATURES === "disabled"
                )
            )
            .map((object) => (
              <div className="usa-radio" key={object.value}>
                <input
                  className="usa-radio__input"
                  id={object.id}
                  key={object.value}
                  type="radio"
                  name="chart-options"
                  value={object.value}
                  defaultChecked={object.defaultChecked}
                  onChange={() => handleChartView("Chart", object.value)}
                />
                <label className="usa-radio__label" htmlFor={object.id}>
                  {" "}
                  <span className="grid-row flex-no-wrap flex-justify-start flex-align-center">
                    <div>{object.value}</div>
                    <Tooltip title={object.tooltip}>
                      <button className="margin-left-1 iconButton">
                        <InfoIcon scale={1} color="#005ea2" />
                      </button>
                    </Tooltip>
                  </span>
                </label>
              </div>
            ))}
        </fieldset>
      ) : (
        <p>Please select a view option</p>
      )}
      <hr />
      <div className="step--filtered-by">
        <h3>Filtered by</h3>
        <fieldset>
          <span className="usa-legend grid-row flex-justify">
            {/* called year reported in the data */}
            <legend>Data Year</legend>
          </span>
          {yearReportedRadios.map((object) => (
            <div className="usa-radio" key={object.value}>
              <input
                className="usa-radio__input"
                id={object.id}
                key={object.value}
                type="radio"
                name="year-reported-radio"
                value={object.value}
                onChange={() => {
                  dispatch({
                    type: "SET_YEAR_REPORTED",
                    payload: object.value,
                  });
                }}
                checked={object.value === yearReported}
                disabled={isTrendsShown}
              />
              <label className="usa-radio__label" htmlFor={object.id}>
                {object.value}
              </label>
            </div>
          ))}
        </fieldset>
      </div>
      {ptFilterGroup ? (
        <MultiSelectDropdown
          setGroup1={(selected: any) =>
            dispatch({ type: "SET_PT_SUBCATEGORIES", payload: selected })
          }
          setGroup2={(selected: any) =>
            dispatch({ type: "SET_PT_CATEGORIES", payload: selected })
          }
          group1={ptCategories}
          group2={ptSubcategories}
          label="Property Type"
          name="property-type"
          data={ptFilterGroup}
          glossaryLink="https://portfoliomanager.energystar.gov/pm/glossary#PropertyType"
        />
      ) : (
        ""
      )}

      <MultiSelectDropdown
        setGroup1={(selected: any) =>
          dispatch({ type: "SET_GFA_GROUPS", payload: selected })
        }
        setGroup2={(selected: any) =>
          dispatch({ type: "SET_GFA_GROUP2S", payload: selected })
        }
        group1={gfaGroups}
        group2={gfaGroup2s}
        label="Gross Floor Area (sq. ft.)"
        name="gross-floor-area"
        data={grossFloorArea}
        glossaryLink="https://portfoliomanager.energystar.gov/pm/glossary#GrossFloorArea"
      />

      <fieldset
        className="usa-fieldset margin-bottom-2"
        onChange={(e) => handleLocationChange(e)}
      >
        <span className="usa-legend grid-row flex-justify">
          <legend>Location</legend>
        </span>
        <div className="usa-radio">
          <input
            className="usa-radio__input"
            id="location_state"
            type="radio"
            name="location"
            value="state"
            defaultChecked={location === "state"}
            // defaultChecked={true}
          />
          <label className="usa-radio__label" htmlFor="location_state">
            {" "}
            <span className="grid-row">
              <div>State</div>
              <Tooltip title="This tool only includes properties in the United States and its territories.">
                <button className="margin-left-1 iconButton">
                  <InfoIcon scale={1} color="#005ea2" />
                </button>
              </Tooltip>
            </span>
          </label>
        </div>
        <div className="usa-radio">
          <input
            className="usa-radio__input"
            id="location_csa"
            type="radio"
            name="location"
            value="csa"
            defaultChecked={location === "csa"}
          />
          <label className="usa-radio__label" htmlFor="location_csa">
            {" "}
            <span className="grid-row">
              <div>CBSA</div>
              <Tooltip title="This tool uses micropolitan and metropolitan statistical areas defined by the United States Office of Management and Budget and displayed at www.census.gov.">
                <button className="margin-left-1 iconButton">
                  <InfoIcon scale={1} color="#005ea2" />
                </button>
              </Tooltip>
            </span>
          </label>
        </div>
        <div className="usa-radio">
          <input
            className="usa-radio__input"
            id="location_climate_zone"
            type="radio"
            name="location"
            value="climate"
            defaultChecked={location === "climate"}
          />
          <label className="usa-radio__label" htmlFor="location_climate_zone">
            {" "}
            <span className="grid-row flex-no-wrap flex-justify-start flex-align-center">
              <div>Climate Zone</div>
              <Tooltip title="This tool uses IECC climate zones: geographical regions that are determined by things like temperature and moisture.">
                <button className="margin-left-1 iconButton">
                  <InfoIcon scale={1} color="#005ea2" />
                </button>
              </Tooltip>
            </span>
          </label>
        </div>
      </fieldset>

      {location === "state" ? (
        <SingleSelectDropdown
          setGroup1={(selected: any) =>
            dispatch({ type: "SET_STATE_PROVINCE_NAMES", payload: selected })
          }
          group1={stateProvinceNames}
          label="State"
          name="state"
          data={stateFilters}
          glossaryLink={undefined}
        />
      ) : location === "climate" ? (
        <div>
          {climateZoneFilterGroup ? (
            <SingleSelectDropdown
              setGroup1={(selected: any) =>
                dispatch({ type: "SET_CLIMATE_ZONE", payload: selected })
              }
              group1={climateZone}
              label="Climate Zone"
              name="climate-zone"
              data={climateZoneFilterGroup}
              glossaryLink={undefined}
            />
          ) : (
            ""
          )}
        </div>
      ) : location === "csa" ? (
        <div>
          {csaFilterGroup ? (
            <MultiSelectDropdown
              setGroup1={(selected: any) =>
                dispatch({ type: "SET_CSA_CITY", payload: selected })
              }
              setGroup2={(selected: any) =>
                dispatch({ type: "SET_CSA_AREA", payload: selected })
              }
              group1={csa_city}
              group2={csa_area}
              label="CBSAs"
              name="cbsas"
              data={csaFilterGroup}
              glossaryLink={undefined}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      <fieldset>
        <span className="usa-legend grid-row flex-justify">
          <legend>ENERGY STAR Certified?</legend>
          <Tooltip title="Glossary (opens in new tab)">
            <a
              href="https://portfoliomanager.energystar.gov/pm/glossary#EnergyStarCertification"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="ENERGY STAR Certified Glossary"
              className={`usa-button usa-button--unstyled iconButton`}
            >
              <GlossaryIcon scale={1} color="#182e4e" />
            </a>
          </Tooltip>
        </span>
        {energyStarCertified.map((object) => (
          <div className="usa-radio" key={object.value}>
            <input
              className="usa-radio__input"
              id={object.id}
              key={object.value}
              type="radio"
              name="energy-star-certified"
              value={object.value}
              checked={object.isCertified === isEnergyStarCertified}
              onChange={() =>
                dispatch({
                  type: "SET_IS_ENERGY_STAR_CERTIFIED",
                  payload: object.isCertified,
                })
              }
            />
            <label className="usa-radio__label" htmlFor={object.id}>
              {object.value}
            </label>
          </div>
        ))}
      </fieldset>
      <CheckboxList
        subHeading={undefined}
        id={"year-built"}
        setDataGroup={(selected: any) =>
          dispatch({ type: "SET_YEAR_BUILT_GROUPS", payload: selected })
        }
        dataGroup={yearBuiltGroups}
        data={yearBuilt}
        label={"Year Built"}
        glossaryLink="https://portfoliomanager.energystar.gov/pm/glossary#YearBuilt"
      ></CheckboxList>
      <CheckboxList
        subHeading={undefined}
        id={"weekly-operating-hours"}
        setDataGroup={(selected: any) =>
          dispatch({ type: "SET_WEEKLY_HOURS_GROUP", payload: selected })
        }
        dataGroup={weeklyHoursGroup}
        data={weeklyHours}
        label={"Weekly Operating Hours"}
        glossaryLink="https://portfoliomanager.energystar.gov/pm/glossary#WeeklyOperatingHours"
      ></CheckboxList>
      {/* <CheckboxList
        subHeading={undefined}
        id={"worker-density"}
        setDataGroup={setWorkerDensityGroups}
        dataGroup={workerDensityGroups}
        data={workerDensity}
        label={"Worker Density (workers/1K sq. ft.)"}
        glossaryLink={
          "https://portfoliomanager.energystar.gov/pm/glossary#NumberOfWorkersOnMainShift"
        }
      ></CheckboxList> */}
    </div>
  );
};

export default FilterSidepanel;
