import React, { FC, useEffect, useRef } from "react";
import { energyUseIntensity } from "../../assets/data/filters";
import styles from "./table.module.scss";
import SortArrow from "../../assets/icons/sortArrow";
import { useAppContext } from "../../AppProvider";

interface TableProps {
  theadData: any;
  results: any;
  groupBy: any;
  thenBy: any;
  readableThenBy: any;
  readableGroupBy: any;
  metric: any;
  groupByResults: any;
  thenByResults: any;
  setGroupByResults: any;
  setThenByResults: any;
  processedAllRow: any;
  setProcessedAllRow: any;
  entries: any;
  setEntries: any;
  sortBy: any;
  setSortBy: any;
}

const Table: FC<TableProps> = ({
  groupByResults,
  thenByResults,
  setGroupByResults,
  setThenByResults,
  metric,
  readableGroupBy,
  readableThenBy,
  groupBy,
  thenBy,
  theadData,
  results,
  processedAllRow,
  setProcessedAllRow,
  entries,
  setEntries,
  sortBy,
  setSortBy,
}) => {
  const { withMedian, withMean, withFifthPercentile, withTwentyFifthPercentile, withSeventyFifthPercentile, withNinetyFifthPercentile } = useAppContext();

  let group0: Array<string> = [];
  let group1: Array<string> = [];
  let resultsTableBodies = document.getElementsByClassName("stickyTable");
  const sortedResultsRef = useRef<any[]>([]);
  const pluralGroupByRef = useRef<string>("");
  const pluralThenByRef = useRef<string>("");
  
  let sortedEntries: any[] = [];

  type HeadingToKeyMap = {
    [heading: string]: string;
  };

  const headingToKeyMap: HeadingToKeyMap = {
    "75th Percentile": "seventyFifthPercentile",
    "25th Percentile": "twentyFifthPercentile",
    "95th Percentile": "ninetyFifthPercentile",
    Median: "median",
    Mean: "mean",
    "5th Percentile": "fifthPercentile",
    "Property Count": "rowCount",
  };

  let propertyCountSortOrder = [
    "≤5",
    "6-29",
    "30-49",
    "50-99",
    "100-249",
    "250-500",
    "500-999",
    "1,000-2,499",
    "2,500-9,999",
    "10,000-49,999",
    "50,000-99,999",
    "100,000+",
  ];

  let gfaSortOrder = [
    "1,000 - 4,999",
    "5,000 - 9,999",
    "10,000 - 24,999",
    "25,000 - 49,999",
    "50,000 - 99,999",
    "100,000 - 199,999",
    "200,000 - 499,999",
    "500,000 - 999,999",
    "1,000,000+",
  ];

  let yearBuiltSortOrder = [
    "Before 1946",
    "1946-1959",
    "1960-1979",
    "1980-1999",
    "2000-2009",
    "2010 and after",
  ];

  const handleSorting = (heading: any) => {
    let newAscending: boolean | null = null; // Default to null
  
    if (sortBy.column === heading) {
      if (sortBy.ascending === null) {
        newAscending = true; // Start ascending
      } else if (sortBy.ascending === true) {
        newAscending = false; // Switch to descending
      } else {
        newAscending = null; // Turn off sorting
      }
    } else {
      newAscending = true; // Start ascending when a new column is clicked
    }
  
    let tableSorting;
  
    if (newAscending !== null) {
      if (thenBy === undefined) {
        // Sorting without secondary sorting
        tableSorting = [...entries].sort((a, b): any => {
          let valueA = a[1][0][heading];
          let valueB = b[1][0][heading];
  
          if (valueA === "-") {
            valueA = -1;
          }
          if (valueB === "-") {
            valueB = -1;
          }
  
          if (heading === "rowCount") {
            valueA = propertyCountSortOrder.indexOf(valueA);
            valueB = propertyCountSortOrder.indexOf(valueB);
          }
  
          // Perform sorting based on newAscending value
          if (newAscending === true) {
            return valueA - valueB;
          } else if (newAscending === false) {
            return valueB - valueA;
          }
  
          // Default return value if conditions are not met
          return 0;
        });
        setEntries(tableSorting);
      } else {
        // Sorting with secondary sorting
        tableSorting = [...entries];
        tableSorting.forEach((entry) => {
          if (entry && entry[1]) {
            entry[1].sort((c: any, d: any) => {
              let valueC = c[heading];
              let valueD = d[heading];
  
              if (valueC === "-") {
                valueC = -1;
              }
              if (valueD === "-") {
                valueD = -1;
              }
  
              if (heading === "rowCount") {
                valueC = propertyCountSortOrder.indexOf(valueC);
                valueD = propertyCountSortOrder.indexOf(valueD);
              }
  
              // Perform sorting based on newAscending value
              if (newAscending !== null && newAscending) {
                return valueC - valueD;
              } else if (newAscending !== null && !newAscending) {
                return valueD - valueC;
              }
  
              // Default return value if conditions are not met
              return 0;
            });
          }
        });
      }
      setSortBy({ column: heading, ascending: newAscending });
    }
    // unsort
    if (newAscending === null) {
      initialSorting();
      setSortBy({ column: "groupBy", ascending: null });
    }
  };
  

  let formatNumber = (d: any) => {
    if (metric === "energyStarScore" || metric === "percentElectricity") {
      return Math.round(d);
    } else {
      return (Math.round(d * 10) / 10).toFixed(1);
    }
  };

  let checkIfSummary = (d: any) => {
    if (d) {
      if (d.toLowerCase().includes("all selected")) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    document
      .getElementById("table")
      ?.addEventListener("scroll", () => handleTableScroll()) as any;

    let handleTableScroll = () => {
      let resultsTableHeaderRow = document.getElementById(
        "stickyHeaderRow"
      ) as HTMLElement;
      let resultsTableSummaryRow = document.getElementById(
        "stickySummaryRow"
      ) as HTMLElement;
      let resultsTableHeadHeight =
        resultsTableHeaderRow.getBoundingClientRect().height +
        resultsTableSummaryRow.getBoundingClientRect().height;
      let tableBodyBottomPosition;
      let tableBodyTopPosition;

      Array.from(resultsTableBodies).forEach(
        (resultsTableBody: any, index: number) => {
          tableBodyBottomPosition =
            resultsTableBody.getBoundingClientRect().bottom;
          tableBodyTopPosition = resultsTableBody.getBoundingClientRect().top;
          let stickyCell = resultsTableBody.querySelector(".stickyCell");
          stickyCell.style.top = resultsTableHeadHeight + "px";
          if (thenBy !== undefined && new Set(group1).size > 2) {
            if (
              tableBodyBottomPosition -
                resultsTableSummaryRow.getBoundingClientRect().height >=
              resultsTableSummaryRow.getBoundingClientRect().bottom
            ) {
              stickyCell.style.position = "sticky";
              stickyCell.style.opacity = 1;
            } else {
              stickyCell.style.position = "relative";
              stickyCell.style.opacity = 0;
            }
            if (
              tableBodyTopPosition >=
              resultsTableSummaryRow.getBoundingClientRect().bottom
            ) {
              stickyCell.style.borderTop = "1px solid black";
            } else {
              stickyCell.style.borderTop = "none";
            }
          } else {
            stickyCell.style.position = "";
            stickyCell.style.top = "";
            stickyCell.style.opacity = 1;
          }
        }
      );
    };
    // eslint-disable-next-line
  }, [thenBy, resultsTableBodies]);

  // this is just for naming language
  useEffect(() => {
    if (groupBy === "ptCategory") {
      pluralGroupByRef.current = "All Selected Property Types";
    } else if (groupBy === "ptSubcategory") {
      pluralGroupByRef.current = "All Selected Property Type Subcategories";
    } else if (groupBy === "gfaGroup") {
      pluralGroupByRef.current = "All Selected Gross Floor Areas";
    } else if (groupBy === "stateProvinceName") {
      pluralGroupByRef.current = "All Selected States";
    } else if (groupBy === "climateZone") {
      pluralGroupByRef.current = "All Selected Climate Zones";
    } else if (groupBy === "csa_city") {
      pluralGroupByRef.current = "All Selected CBSAs";
    } else if (groupBy === "yearBuiltGroup") {
      pluralGroupByRef.current = "All Selected Years Built";
    }

    if (thenBy === "ptCategory") {
      pluralThenByRef.current = "All Selected Property Types";
    } else if (thenBy === "ptSubcategory") {
      pluralThenByRef.current = "All Selected Property Type Subcategories";
    } else if (thenBy === "gfaGroup") {
      pluralThenByRef.current = "All Selected Gross Floor Areas";
    } else if (thenBy === "climateZone") {
      pluralThenByRef.current = "All Selected Climate Zones";
    } else if (thenBy === "stateProvinceName") {
      pluralThenByRef.current = "All Selected States";
    } else if (thenBy === "yearBuiltGroup") {
      pluralThenByRef.current = "All Selected Years Built";
    }
  }, [groupBy, thenBy]);

  useEffect(() => {
    results &&
      results.getResults &&
      results.getResults.results.forEach((d: any) => {
        let group = d.group;
        if (thenBy !== undefined) {
          if (group.includes("+")) {
            if (group.includes("00,000+")) {
              group0.push(
                group
                  .split(" + ")[0]
                  .trim()
                  .replace("All", pluralGroupByRef.current)
              );
              if (group.split(" + ")[1].trim() === "All") {
                group1.push(pluralThenByRef.current);
              } else {
                group1.push(group.split(" + ")[1].trim());
              }
            } else {
              group0.push(
                group
                  .split("+")[0]
                  .trim()
                  .replace("All ", pluralGroupByRef.current)
              );
              if (group.split("+")[1].trim() === "All") {
                group1.push(pluralThenByRef.current);
              } else {
                group1.push(group.split("+")[1].trim());
              }
            }
          } else if (group === "All") {
            group0.push(pluralGroupByRef.current);
            group1.push(pluralThenByRef.current);
          } else {
            group0.push(group);
            group1.push(group);
          }
        }
        if (thenBy === undefined || thenBy === "") {
          if (group === "All") {
            group0.push(pluralGroupByRef.current);
          } else {
            group0.push(group);
          }
        }
      });
    setThenByResults(group1);
    setGroupByResults(group0);
    // eslint-disable-next-line
  }, [results]);

  // initial sorting logic
  const initialSorting = () => {
    let nulledData: any[] = [];

    if (results && results.getResults) {
      let nulledRows: any = {};

      results.getResults.results.forEach((d: any) => {
        nulledRows = Object.keys(d).reduce((acc: any, key) => {
          acc[key] = d[key] === -1 ? "-" : d[key];
          return acc;
        }, {});
        nulledData.push(nulledRows);
      });

      const sortedResults = nulledData.reduce(
        (result: any, elem: any, i: number) => {
          result.push({
            rowCount: elem.rowCount,
            group: elem.group,
            median: elem.median,
            groupBy: groupByResults[i],
            thenBy: thenByResults[i],
            mean: elem.mean,
            twentyFifthPercentile: elem.twentyFifthPercentile,
            seventyFifthPercentile: elem.seventyFifthPercentile,
            ninetyFifthPercentile: elem.ninetyFifthPercentile,
            fifthPercentile: elem.fifthPercentile,
          });
          return result;
        },
        []
      );
      sortedResultsRef.current = sortedResults;
    }

    // let sortedEntries: any[] = [];
    if (results && results.getResults) {
      sortedEntries = nulledData.reduce((result: any, elem: any, i: number) => {
        result[groupByResults[i]] = result[groupByResults[i]] || [];
        result[groupByResults[i]].push({
          rowCount: elem.rowCount,
          group: elem.group,
          median: elem.median,
          groupBy: groupByResults[i],
          thenBy: thenByResults[i],
          mean: elem.mean,
          twentyFifthPercentile: elem.twentyFifthPercentile,
          seventyFifthPercentile: elem.seventyFifthPercentile,
          ninetyFifthPercentile: elem.ninetyFifthPercentile,
          fifthPercentile: elem.fifthPercentile,
        });
        return result;
      }, []);
      sortedEntries = Object.entries(sortedEntries);
    }

    customSorting();

    sortedEntries.shift();

    setEntries(sortedEntries);
    setProcessedAllRow(sortedResultsRef.current.shift());
    // eslint-disable-next-line
  };

  useEffect(() => {
    initialSorting();
    setSortBy({ column: "groupBy", ascending: null });
        // eslint-disable-next-line
  }, [groupByResults, thenByResults, results, groupBy, thenBy]);

  // for sorting categories that aren't in a strict a to z  or numeric order
  const customSorting = () => {
    sortedEntries.sort(function (a: any, b: any) {
      if (groupBy === "yearBuiltGroup") {
        return yearBuiltSortOrder.indexOf(a[0]) - yearBuiltSortOrder.indexOf(b[0]);
      } else if (groupBy === "gfaGroup") {
        return gfaSortOrder.indexOf(a[0]) - gfaSortOrder.indexOf(b[0]);
      }
      return 0;
    });
  
    if (thenBy !== undefined) {
      sortedEntries.forEach((group: any) => {
        group[1].sort(function (c: any, d: any) {
          if (c["thenBy"] !== undefined && d["thenBy"] !== undefined) {
            const cAllRow = c["thenBy"].includes("All Selected");
            const dAllRow = d["thenBy"].includes("All Selected");
  
            if (cAllRow && !dAllRow) {
              return -1;
            } else if (!cAllRow && dAllRow) {
              return 1;
            } else {
              if (thenBy === "yearBuiltGroup") {
                return yearBuiltSortOrder.indexOf(c["thenBy"]) - yearBuiltSortOrder.indexOf(d["thenBy"]);
              } else if (thenBy === "gfaGroup") {
                return gfaSortOrder.indexOf(c["thenBy"]) - gfaSortOrder.indexOf(d["thenBy"]);
              }
            }
          }
          return 0;
        });
      });
    }
  };
  const checkedCountFromState = Number(withMedian) + Number(withMean) + Number(withFifthPercentile) + Number(withTwentyFifthPercentile) + Number(withSeventyFifthPercentile) + Number(withNinetyFifthPercentile);
  
  const percentileVisibility = theadData.reduce((acc: any, heading: any) => {
    switch (heading) {
      case "Median":
        acc[heading] = withMedian;
        break;
      case "Mean":
        acc[heading] = withMean;
        break;
      case "5th Percentile":
        acc[heading] = withFifthPercentile;
        break;
      case "25th Percentile":
        acc[heading] = withTwentyFifthPercentile;
        break;
      case "75th Percentile":
        acc[heading] = withSeventyFifthPercentile;
        break;
      case "95th Percentile":
        acc[heading] = withNinetyFifthPercentile;
        break;
      default:
        break;
    }
    return acc;
  }
  , {});

  return (
    <div className={styles.Table}>
      <div
        id="usa-table-container"
        className={`usa-table-container--scrollable usa-table--compact usa-table--borderless ${styles.usaTableContainer}`}
        tabIndex={0}
      >
        <div id="select-col-error-msg" style={{display: checkedCountFromState > 0 ? "none" : "block"}}>
          <h3>No table columns selected</h3>
          <p>
            Select which table columns you would like to see from the filters
            panel
          </p>
        </div>
        <p aria-hidden={false} id="tblDesc" className="chartDesc">
          {energyUseIntensity.find((x) => x.name === metric)?.value} grouped by{" "}
          {readableGroupBy}
          {thenBy !== undefined ? ` then by ${readableThenBy}` : "."}
        </p>
        {
        }
        <table
          aria-describedby="tblDesc"
          tabIndex={1}
          className={`usa-table usa-table--borderless ${styles.tableScroll}`}
          id="table"
          style={{ display: checkedCountFromState > 0 ? "block" : "none" }}
        >
          <colgroup>
            {theadData.map((heading: any, i: any) => {
          
              if (i === 0 && groupBy !== undefined) {
                return (
                  <col
                    key={0}
                    className={`col${0}`}
                    data-column={readableGroupBy}
                  />
                );
              } else if (i === 1 && thenBy !== undefined) {
                return (
                  <col
                    key={1}
                    className={`col${1}`}
                    data-column={readableThenBy}
                  />
                );
              } else if (i > 1 && percentileVisibility[heading]) {
                return (
                  <col key={i} className={`col${i}`} data-column={heading} />
                );
              } else {
                return null;
              }
            })}
          </colgroup>
          <thead>
            <tr className={styles.headerRow} id="stickyHeaderRow">
              {theadData.map((heading: any, idx: any) => {
                if (idx === 0 && groupBy !== undefined) {
                  return (
                    <th
                      className={styles.usTableCell}
                      role="columnheader"
                      key={idx}
                    >
                      {readableGroupBy}
                    </th>
                  );
                } else if (idx === 1 && thenBy !== undefined) {
                  return (
                    <th
                      className={styles.usTableCell}
                      role="columnheader"
                      key={idx}
                    >
                      <span>{readableThenBy}</span>
                    </th>
                  );
                } else if (idx > 1 && (percentileVisibility[heading] || heading === "Property Count")) {
                  return (
                    <th
                      className={styles.usTableCell}
                      role="columnheader"
                      key={idx}
                      onClick={() => handleSorting(headingToKeyMap[heading])}
                    >
                     <span className={`${styles.sortableCell} sortableCell`}>
                        {heading}
                        <SortArrow
                          ascending={sortBy.ascending}
                          selected={sortBy.column === headingToKeyMap[heading]}
                        />
                      </span>
                    </th>
                  );
                }
                return null;
              })}
            </tr>
            {processedAllRow && (
              <tr key={0} className="boldRow" id="stickySummaryRow">
                {theadData.map((heading: any, idx: any) => {
                  if (heading === "Median" && percentileVisibility[heading]) {
                    return (
                      <td key={idx}>
                        {typeof processedAllRow.median == "number"
                          ? formatNumber(processedAllRow.median)
                          : processedAllRow.median}
                      </td>
                    );
                  } else if (heading === "Mean" && percentileVisibility[heading]) {
                    return (
                      <td key={idx}>
                        {typeof processedAllRow.mean == "number"
                          ? formatNumber(processedAllRow.mean)
                          : processedAllRow.mean}
                      </td>
                    );
                  } else if (heading === "5th Percentile" && percentileVisibility[heading]) {
                    return (
                      <td key={idx}>
                        {typeof processedAllRow.fifthPercentile == "number"
                          ? formatNumber(processedAllRow.fifthPercentile)
                          : processedAllRow.fifthPercentile}
                      </td>
                    );
                  } else if (heading === "25th Percentile" && percentileVisibility[heading]) {
                    return (
                      <td key={idx}>
                        {typeof processedAllRow.twentyFifthPercentile ==
                        "number"
                          ? formatNumber(processedAllRow.twentyFifthPercentile)
                          : processedAllRow.twentyFifthPercentile}
                      </td>
                    );
                  } else if (heading === "75th Percentile" && percentileVisibility[heading]) {
                    return (
                      <td key={idx}>
                        {typeof processedAllRow.seventyFifthPercentile ==
                        "number"
                          ? formatNumber(processedAllRow.seventyFifthPercentile)
                          : processedAllRow.seventyFifthPercentile}
                      </td>
                    );
                  } else if (heading === "95th Percentile" && percentileVisibility[heading]) {
                    return (
                      <td key={idx}>
                        {" "}
                        {typeof processedAllRow.ninetyFifthPercentile ==
                        "number"
                          ? formatNumber(processedAllRow.ninetyFifthPercentile)
                          : processedAllRow.ninetyFifthPercentile}
                      </td>
                    );
                  } else if (heading === "Property Count") {
                    return <td key={idx}>{processedAllRow.rowCount}</td>;
                  } else if (idx === 0 && groupBy !== undefined) {
                    return (
                      <td role="columnheader" key={idx}>
                        {processedAllRow.groupBy}
                      </td>
                    );
                  } else if (idx === 1 && thenBy !== undefined) {
                    return <td key={idx}>{processedAllRow.thenBy}</td>;
                  }
                  return null;
                })}
              </tr>
            )}
          </thead>
          {entries &&
            entries.map((entry: any, i: number) => {
              return (
                <tbody
                  key={i}
                  className="stickyTable"
                  id={`resultsTableBody-${i}`}
                >
                  {entry[1] &&
                    entry[1].map((row: any, index: number) => {
                      const isSummaryRow = checkIfSummary(row.thenBy);
                      return (
                        <tr
                          key={index}
                          className={isSummaryRow ? `${styles.summaryRow}` : ""}
                        >
                          {theadData.map((heading: any, idx: any) => {
                            if (heading === "Median" && percentileVisibility[heading]) {
                              return (
                                <td
                                  className={styles.usTableCell}
                                  key={`${index}-${idx}`}
                                >
                                  {typeof entry[1][index].median == "number"
                                    ? formatNumber(entry[1][index].median)
                                    : entry[1][index].median}
                                </td>
                              );
                            } else if (heading === "Mean" && percentileVisibility[heading]) {
                              return (
                                <td
                                  className={styles.usTableCell}
                                  key={`${index}-${idx}`}
                                >
                                  {typeof entry[1][index].mean == "number"
                                    ? formatNumber(entry[1][index].mean)
                                    : entry[1][index].mean}
                                </td>
                              );
                            } else if (heading === "5th Percentile" && percentileVisibility[heading]) {
                              return (
                                <td
                                  className={styles.usTableCell}
                                  key={`${index}-${idx}`}
                                >
                                  {typeof entry[1][index].fifthPercentile ==
                                  "number"
                                    ? formatNumber(
                                        entry[1][index].fifthPercentile
                                      )
                                    : entry[1][index].fifthPercentile}
                                </td>
                              );
                            } else if (heading === "25th Percentile" && percentileVisibility[heading]) {
                              return (
                                <td
                                  className={styles.usTableCell}
                                  key={`${index}-${idx}`}
                                >
                                  {typeof entry[1][index]
                                    .twentyFifthPercentile == "number"
                                    ? formatNumber(
                                        entry[1][index].twentyFifthPercentile
                                      )
                                    : entry[1][index].twentyFifthPercentile}
                                </td>
                              );
                            } else if (heading === "75th Percentile" && percentileVisibility[heading]) {
                              return (
                                <td
                                  className={styles.usTableCell}
                                  key={`${index}-${idx}`}
                                >
                                  {typeof entry[1][index]
                                    .seventyFifthPercentile == "number"
                                    ? formatNumber(
                                        entry[1][index].seventyFifthPercentile
                                      )
                                    : entry[1][index].seventyFifthPercentile}
                                </td>
                              );
                            } else if (heading === "95th Percentile" && percentileVisibility[heading]) {
                              return (
                                <td
                                  className={styles.usTableCell}
                                  key={`${index}-${idx}`}
                                >
                                  {typeof entry[1][index]
                                    .ninetyFifthPercentile == "number"
                                    ? formatNumber(
                                        entry[1][index].ninetyFifthPercentile
                                      )
                                    : entry[1][index].ninetyFifthPercentile}
                                </td>
                              );
                            } else if (heading === "Property Count") {
                              return (
                                <td
                                  className={styles.usTableCell}
                                  key={`${index}-${idx}`}
                                >
                                  {" "}
                                  {entry[1][index].rowCount.toLocaleString()}
                                </td>
                              );
                            } else if (
                              idx === 0 &&
                              groupBy !== undefined &&
                              index === 0
                            ) {
                              return (
                                <td
                                  rowSpan={entry[1].length}
                                  role="columnheader"
                                  key={`${index}-${idx}`}
                                  className={`${styles.stickyCell} stickyCell usTableCell`}
                                >
                                  {entry[1][index].groupBy}
                                </td>
                              );
                            } else if (idx === 1 && thenBy !== undefined) {
                              return (
                                <td
                                  className={styles.usTableCell}
                                  key={`${index}-${idx}`}
                                >
                                  {entry[1][index].thenBy}
                                </td>
                              );
                            } 
                            return null;
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              );
            })}
        </table>
      </div>
    </div>
  );
};

export default Table;
