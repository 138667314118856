import React from "react";
import "./App.scss";
import DataExplorerPageContainer from "./pages/dataExplorerPage";

import GlossaryIcon from "../src/assets/icons/glossary";
import QuestionIcon from "../src/assets/icons/question";
import DownloadIcon from "../src/assets/icons/download";
import logo from "./assets/images/logo.svg";
import { Tooltip } from "@mui/material";
import TagManager from "react-gtm-module";
import { useDispatchContext } from "./AppProvider";

const tagManagerArgs = {
  gtmId: "GTM-L8ZB",
};

TagManager.initialize(tagManagerArgs);

function App() {
  const dispatch = useDispatchContext();

  const bannerMsg = process.env.REACT_APP_BANNER_MSG;
  const showBanner = !!bannerMsg;

  return (
    <div className="App">
      {showBanner && (
        <section
          className="usa-site-alert usa-site-alert--emergency"
          aria-label="Site alert"
        >
          <div className="usa-alert">
            <div className="usa-alert__body">
              <p className="usa-alert__text">{bannerMsg}</p>
            </div>
          </div>
        </section>
      )}

      <header className="header grid-row flex-justify flex-align-center width-full">
        <div className="headerLogo grid-row flex-justify-start flex-align-center flex-no-wrap">
          <a
            href="https://www.energystar.gov/buildings/resources-topic/portfolio-manager-data-explorer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logo}
              width={150}
              height={45}
              alt="Portfolio Manager Data Explorer logo"
            />
          </a>
        </div>
        <div className="headerButtons grid-row flex-justify-end flex-align-center flex-no-wrap">
          <div className="headerButtonsInner">
            <Tooltip
              title="Glossary (opens in new tab)"
              className="step--glossary"
            >
              <a
                href="https://portfoliomanager.energystar.gov/pm/glossary"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Glossary"
                className={`usa-button usa-button--unstyled iconButton`}
              >
                <GlossaryIcon scale={1.25} color="#005ea2" />
              </a>
            </Tooltip>
            <Tooltip title="Help" className="step--help">
              <button
                onClick={() =>
                  dispatch({
                    type: "SET_DRAWER_OPEN",
                    payload: {
                      open: true,
                      drawerView: "Help",
                    },
                  })
                }
                aria-label={`Open Help`}
                className={`usa-button usa-button--unstyled iconButton`}
              >
                <QuestionIcon scale={0.85} color="#005ea2" />
              </button>
            </Tooltip>
            <button
              onClick={() =>
                dispatch({
                  type: "SET_DRAWER_OPEN",
                  payload: {
                    open: true,
                    drawerView: "Download",
                  },
                })
              }
              aria-label={`Open Download`}
              className="download-button display-flex flex-align-center usa-button usa-button--outline step--download"
            >
              <DownloadIcon color="#005ea2" scale={1} />
              Download
            </button>
          </div>
        </div>
      </header>
      <main>
        <DataExplorerPageContainer />
      </main>
      <footer className="footer grid-row flex-justify-end width-full">
        <a
          className="footer-hyperlink"
          href="https://energystar.my.site.com/PortfolioManager/s/contactsupport"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </a>
        <a
          className="footer-hyperlink"
          href="https://www.epa.gov/accessibility/epa-accessibility-statement"
          target="_blank"
          rel="noopener noreferrer"
        >
          Accessibility Statement
        </a>
        <a
          className="footer-hyperlink"
          href="http://www.energystar.gov/buildings/resources_topic/energy_star_portfolio_manager_data_explorer#AboutTheData"
          target="_blank"
          rel="noopener noreferrer"
        >
          About the Data
        </a>
        <a
          className="footer-hyperlink"
          href="https://www.energystar.gov/privacy_and_security_notice"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Statement
        </a>
      </footer>
    </div>
  );
}

export default App;
