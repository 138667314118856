import DataExplorerPage from "./dataExplorerPage";
import { FC } from "react";
import ErrorPage from "../errorPage/errorPage";
import { useResultsQuery } from "../../AppProvider";
import { GetResultsQuery } from "../../graphql/generated/graphql";

const DataExplorerPageContainer: FC = () => {
  const { error } = useResultsQuery<GetResultsQuery>();

  if (error) return <ErrorPage message={error.message} />;

  return <DataExplorerPage />;
};

export default DataExplorerPageContainer;
