import React, { FC, useState } from "react";
import styles from "./download.module.scss";
import { saveAs } from "file-saver";
import * as excelJS from "exceljs";
import { energyUseIntensity } from "../../assets/data/filters";
import { useAppContext, useResultsQuery } from "../../AppProvider";
import { GetResultsQuery } from "../../graphql/generated/graphql";

interface DownloadProps {
  readableThenBy: any;
  readableGroupBy: any;
  entries: any;
  processedAllRow: any;
  sortedChartData: any;
  view: any;
}

const Download: FC<DownloadProps> = ({
  readableThenBy,
  readableGroupBy,
  entries,
  processedAllRow,
  sortedChartData,
  view,
}) => {
  const {
    withMean,
    withMedian,
    withFifthPercentile,
    withTwentyFifthPercentile,
    withSeventyFifthPercentile,
    withNinetyFifthPercentile,
    metric,
    isEnergyStarCertified,
    yearReported,
    yearBuiltGroups,
    stateProvinceNames,
    gfaGroups,
    gfaGroup2s,
    weeklyHoursGroup,
    ptCategories,
    ptSubcategories,
    csa_area,
    csa_city,
    climateZone,
    groupBy,
    thenBy,
    location,
  } = useAppContext();
  const {data: results} = useResultsQuery<GetResultsQuery>();

  const [fileType, setFileType] = useState("Excel");

  function ConvertToCSV(objArray: any) {
    let str = "";
    str += Object.keys(objArray[0]) + "\r\n";
    for (let i = 0; i < objArray.length; i++) {
      let line = "";
      for (let index in objArray[i]) {
        if (line !== "") line += ",";
        line += objArray[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  function handleFileTypeChange(e: any) {
    setFileType(e.target.value);
  }

  let formatNumber = (d: any) => {
    if (metric === "energyStarScore" || metric === "percentElectricity") {
      return Number(Math.round(d));
    } else {
      return Number((Math.round(d * 10) / 10).toFixed(1));
    }
  };

  function formatValue(value: any): string {
    if (fileType === "CSV") {
      // Example: "6-29"
      const rangePattern = /^\d+-\d+$/; 
  
      if (rangePattern.test(value)) {
        return `" ${value.toString()}"`; // Prevent ranges from being converted to dates
      }
  
      return `"${value.toString()}"`; // Wrap in quotes for CSV
    }
  
    return value.toString(); // No wrapping for other cases
  }

  // get metric info for report name
  const selectedMetric = energyUseIntensity.find(
    (item) => item.name === metric
  );
  const metricID = selectedMetric ? selectedMetric.id : metric;
  const metricUnits = selectedMetric ? selectedMetric.unit : metric;
  const metricValue = selectedMetric ? selectedMetric.value : metric;
  let sortedResults: any[] = [];

  if (processedAllRow && entries) {
    const restructuredAllRow = [processedAllRow.groupBy, [processedAllRow]];

    sortedResults = [restructuredAllRow, ...entries];
  }

  function downloadResults(this: any) {
    let formattedResults: any;
    let filename: any;
    let readme: string;
    let excelReadme: string;

    if (results && results.getResults) {
      if (
        results.getResults.results &&
        (results?.getResults?.count === "≤5" ||
          results.getResults.results.length <= 1)
      ) {
        formattedResults = [{ Results: "Not enough results" }];
      } else {
        if (view === "Table") {
          formattedResults = sortedResults.reduce(
            (result: any, elem: any) => {
              elem[1].forEach((d: any) => {
                if (d.rowCount !== "≤5") {
                  result.push({
                    ...(groupBy && {
                      [readableGroupBy]: formatValue(d.groupBy),
                    }),
                    ...(thenBy && { [readableThenBy]: formatValue(d.thenBy) }),
                    ...(withFifthPercentile && {
                      "Fifth Percentile": formatNumber(d.fifthPercentile),
                    }),
                    ...(withTwentyFifthPercentile && {
                      "Twenty-fifth Percentile": formatNumber(
                        d.twentyFifthPercentile
                      ),
                    }),
                    ...(withMedian && { Median: formatNumber(d.median) }),
                    ...(withMean && { Mean: formatNumber(d.mean) }),
                    ...(withSeventyFifthPercentile && {
                      "Seventy-fifth Percentile": formatNumber(
                        d.seventyFifthPercentile
                      ),
                    }),
                    ...(withNinetyFifthPercentile && {
                      "Ninety-fifth Percentile": formatNumber(
                        d.ninetyFifthPercentile
                      ),
                    }),
                    "Property Count": formatValue(d.rowCount),
                  });
                }
              });
              return result;
            },
            []
          );
        } else {
          formattedResults = sortedChartData.reduce(
            (result: any, elem: any) => {
              if (elem.rowCount !== "≤5") {
                result.push({
                  ...(groupBy && {
                    [readableGroupBy]: formatValue(elem.group),
                  }),
                  ...(thenBy && { [readableThenBy]: formatValue(elem.thenBy) }),
                  ...(withFifthPercentile && {
                    "Fifth Percentile": formatNumber(elem.fifthPercentile),
                  }),
                  ...(withTwentyFifthPercentile && {
                    "Twenty-fifth Percentile": formatNumber(
                      elem.twentyFifthPercentile
                    ),
                  }),
                  ...(withMedian && { Median: formatNumber(elem.median) }),
                  ...(withMean && { Mean: formatNumber(elem.mean) }),
                  ...(withSeventyFifthPercentile && {
                    "Seventy-fifth Percentile": formatNumber(
                      elem.seventyFifthPercentile
                    ),
                  }),
                  ...(withNinetyFifthPercentile && {
                    "Ninety-fifth Percentile": formatNumber(
                      elem.ninetyFifthPercentile
                    ),
                  }),
                  "Property Count": formatValue(elem.rowCount),
                });
              }
              return result;
            },
            []
          );
        }
      }

      let readmeFileName =
        "applied-filters-" +
        metricID +
        "-" +
        new Date().toISOString().substring(0, 10) +
        ".md";

      if (fileType !== "Excel") {
        let format = fileType.toLowerCase();
        let data: any;
        filename =
          "report-" +
          metricID +
          "-" +
          new Date().toISOString().substring(0, 10) +
          "." +
          format;

        if (format === "json") {
          data = new Blob([JSON.stringify(formattedResults)]);
        } else if (format === "csv") {
          data = new Blob([ConvertToCSV(formattedResults)]);
        }
        saveAs(data, filename);
      } else if (fileType === "Excel") {
        filename =
          "report-" +
          metricID +
          "-" +
          new Date().toISOString().substring(0, 10) +
          ".xlsx";
        const workbook = new excelJS.Workbook();
        workbook.created = new Date();
        workbook.modified = new Date();

        let worksheet = workbook.addWorksheet("Report");

        let headers = Object.keys(formattedResults[0]);

        worksheet.getRow(1).values = headers;
        let columns: any = [];

        headers.forEach((header: any) => {
          columns.push({ key: header, width: 30 });
        });

        worksheet.columns = columns;
        worksheet.addRows(formattedResults);

        const row = worksheet.getRow(1);
        row.eachCell((cell, rowNumber) => {
          worksheet.getColumn(rowNumber).font = { size: 14, family: 2 };
        });

        // Temporarily saving excelReadme string with Worker Density included

        // excelReadme = `
        // Filter Name: Filters Selected
        // Metric: ${energyUseIntensity.find((x: any) => x.name === metric)?.value}
        // ENERGY STAR Certified?: ${
        //   isEnergyStarCertified === 1
        //     ? "Yes"
        //     : isEnergyStarCertified === 0
        //     ? "No"
        //     : "SHOW ALL"
        // }
        // Year Built: ${
        //   yearBuiltGroups.length <= 0
        //     ? "Before 1946 – After 2000"
        //     : yearBuiltGroups
        // }
        // Weekly Hours: ${
        //   weeklyHoursGroup.length <= 0
        //     ? "<40 – Open Continuously"
        //     : weeklyHoursGroup
        // }
        // Worker Density: ${
        //   workerDensityGroups.length <= 0 ? "<0.2 – 3+" : workerDensityGroups
        // }
        // Gross Floor Area (sq. ft.): ${
        //   (gfaGroup2s === undefined || gfaGroup2s.length < 1) &&
        //   (gfaGroups === undefined || gfaGroups.length < 1)
        //     ? "1,000 – 500,000+"
        //     : (gfaGroup2s === undefined || gfaGroup2s.length < 1) &&
        //       gfaGroups &&
        //       gfaGroups.length >= 1
        //     ? gfaGroups
        //     : gfaGroup2s &&
        //       gfaGroup2s.length >= 1 &&
        //       (gfaGroups === undefined || gfaGroups.length < 1)
        //     ? gfaGroup2s
        //     : gfaGroups + ", " + gfaGroup2s
        // }
        // Property Types: ${
        //   (ptCategories === undefined || ptCategories.length < 1) &&
        //   (ptSubcategories === undefined || ptSubcategories.length < 1)
        //     ? "All"
        //     : (ptCategories === undefined || ptCategories.length < 1) &&
        //       ptSubcategories &&
        //       ptSubcategories.length >= 1
        //     ? ptSubcategories
        //     : ptCategories &&
        //       ptCategories.length >= 1 &&
        //       (ptSubcategories === undefined || ptSubcategories.length < 1)
        //     ? ptCategories
        //     : ptSubcategories + ", " + ptCategories
        // },
        // ${
        //   location === "state"
        //     ? "States"
        //     : location === "csa"
        //     ? "CBSAs"
        //     : location === "climate"
        //     ? "Climate Zones"
        //     : ""
        // }: ${
        //     location === "state"
        //       ? `${stateProvinceNames.length <= 0 ? "All" : stateProvinceNames}`
        //       : location === "csa"
        //       ? `${
        //           (csa_area === undefined || csa_area.length < 1) &&
        //           (csa_city === undefined || csa_city.length < 1)
        //             ? "All"
        //             : (csa_area === undefined || csa_area.length < 1) &&
        //               csa_city &&
        //               csa_city.length >= 1
        //             ? csa_city
        //             : csa_area &&
        //               csa_area.length >= 1 &&
        //               (csa_city === undefined || csa_city.length < 1)
        //             ? csa_area
        //             : csa_city + ", " + csa_area
        //         }`
        //       : location === "climate"
        //       ? `${climateZone.length <= 0 ? "All" : climateZone}`
        //       : ""
        //   }
        // Data Year: ${yearReported}
        // `;

        excelReadme = `
      Filter Name: Filters Selected 
      Metric: ${metricValue} ${metricUnits}
      ENERGY STAR Certified?: ${
        isEnergyStarCertified === true
          ? "Yes"
          : isEnergyStarCertified === false
          ? "No"
          : "SHOW ALL"
      }
      Year Built: ${
        yearBuiltGroups === undefined ||
        (yearBuiltGroups && yearBuiltGroups.length <= 0)
          ? "All"
          : yearBuiltGroups.join(", ")
      }
      Weekly Hours: ${
        weeklyHoursGroup === undefined ||
        (weeklyHoursGroup && weeklyHoursGroup.length <= 0)
          ? "All"
          : weeklyHoursGroup.join(", ")
      }
      Gross Floor Area (sq. ft.): ${
        (gfaGroup2s === undefined || (gfaGroup2s && gfaGroup2s.length < 1)) &&
        (gfaGroups === undefined || (gfaGroups && gfaGroups.length < 1))
          ? "All"
          : (gfaGroup2s === undefined ||
              (gfaGroup2s && gfaGroup2s.length < 1)) &&
            gfaGroups &&
            gfaGroups.length >= 1
          ? gfaGroups.join(", ")
          : gfaGroup2s &&
            gfaGroup2s.length >= 1 &&
            (gfaGroups === undefined || (gfaGroups && gfaGroups.length < 1))
          ? gfaGroup2s.join(", ")
          : (gfaGroups || []).concat(gfaGroup2s).join(", ")
      }
      Property Types: ${
        (ptCategories === undefined ||
          (ptCategories && ptCategories.length < 1)) &&
        (ptSubcategories === undefined ||
          (ptSubcategories && ptSubcategories.length < 1))
          ? "All"
          : (ptCategories === undefined ||
              (ptCategories && ptCategories.length < 1)) &&
            ptSubcategories &&
            ptSubcategories.length >= 1
          ? ptSubcategories.join(", ")
          : ptCategories &&
            ptCategories.length >= 1 &&
            (ptSubcategories === undefined ||
              (ptSubcategories && ptSubcategories.length < 1))
          ? ptCategories.join(", ")
          : (ptSubcategories || []).concat(ptCategories).join(", ")
      }
      ${
        location === "state"
          ? "States"
          : location === "csa"
          ? "CBSAs"
          : location === "climate"
          ? "Climate Zones"
          : ""
      }: ${
          location === "state"
            ? `${
                stateProvinceNames === undefined ||
                (stateProvinceNames && stateProvinceNames.length <= 0)
                  ? "All"
                  : stateProvinceNames.join(", ")
              }`
            : location === "csa"
            ? `${
                (csa_area === undefined || (csa_area && csa_area.length < 1)) &&
                (csa_city === undefined || (csa_city && csa_city.length < 1))
                  ? "All"
                  : (csa_area === undefined ||
                      (csa_area && csa_area.length < 1)) &&
                    csa_city &&
                    csa_city.length >= 1
                  ? csa_city.join(", ")
                  : csa_area &&
                    csa_area.length >= 1 &&
                    (csa_city === undefined ||
                      (csa_city && csa_city.length < 1))
                  ? csa_area.join(", ")
                  : (csa_city || []).concat(csa_area).join(", ")
              }`
            : location === "climate"
            ? `${
                climateZone === undefined ||
                (climateZone && climateZone.length <= 0)
                  ? "All"
                  : climateZone.join(", ")
              }`
            : ""
        }
      Data Year: ${yearReported}
      `;

        let readmeRows = excelReadme
          .split("\n")
          .map((line) => line.trim())
          .filter((line) => line !== "");

        if (fileType === "Excel" && readmeRows && readmeRows.length > 0) {
          const readmeWorksheet = workbook.addWorksheet("Applied Filters");

          readmeRows.forEach((line, index) => {
            const separatorIndex = line.indexOf(":");
            if (separatorIndex !== -1) {
              const leftColumn = line.slice(0, separatorIndex).trim();
              const rightColumn = line.slice(separatorIndex + 1).trim();
              readmeWorksheet.addRow([leftColumn, rightColumn]);
            } else {
              readmeWorksheet.addRow([line]);
            }

            readmeWorksheet.getColumn("A").width = 30;
            readmeWorksheet.getColumn("B").width = 30;

            const row = readmeWorksheet.getRow(index + 1);
            row.font = { size: 14 };
          });
        }

        workbook.xlsx.writeBuffer().then(function (buffer) {
          let blob = new Blob([buffer], { type: "applicationi/xlsx" });
          saveAs(blob, filename);
        });
      }

      readme = `

    ** Filters applied for ${filename} **

    - Metric
      - ${metricValue} ${metricUnits}
    - ENERGY STAR Certified?
      - ${
        isEnergyStarCertified === true
          ? "Yes"
          : isEnergyStarCertified === false
          ? "No"
          : "SHOW ALL"
      }
    - Year Built
      - ${
        yearBuiltGroups === undefined ||
        (yearBuiltGroups && yearBuiltGroups.length <= 0)
          ? "All"
          : yearBuiltGroups.join(", ")
      }
    - Weekly Hours
      - ${
        weeklyHoursGroup === undefined ||
        (weeklyHoursGroup && weeklyHoursGroup.length <= 0)
          ? "All"
          : weeklyHoursGroup.join(", ")
      }
    - Gross Floor Area (sq. ft.)
      - ${
        (gfaGroup2s === undefined || (gfaGroup2s && gfaGroup2s.length < 1)) &&
        (gfaGroups === undefined || (gfaGroups && gfaGroups.length < 1))
          ? "All"
          : (gfaGroup2s === undefined ||
              (gfaGroup2s && gfaGroup2s.length < 1)) &&
            gfaGroups &&
            gfaGroups.length >= 1
          ? gfaGroups.join(", ")
          : gfaGroup2s &&
            gfaGroup2s.length >= 1 &&
            (gfaGroups === undefined || (gfaGroups && gfaGroups.length < 1))
          ? gfaGroup2s.join(", ")
          : (gfaGroups || []).concat(gfaGroup2s).join(", ")
      }
    - Property Types
      - ${
        (ptCategories === undefined ||
          (ptCategories && ptCategories.length < 1)) &&
        (ptSubcategories === undefined ||
          (ptSubcategories && ptSubcategories.length < 1))
          ? "All"
          : (ptCategories === undefined ||
              (ptCategories && ptCategories.length < 1)) &&
            ptSubcategories &&
            ptSubcategories.length >= 1
          ? ptSubcategories.join(", ")
          : ptCategories &&
            ptCategories.length >= 1 &&
            (ptSubcategories === undefined ||
              (ptSubcategories && ptSubcategories.length < 1))
          ? ptCategories.join(", ")
          : (ptSubcategories || []).concat(ptCategories).join(", ")
      }  
    - ${
      location === "state"
        ? "States"
        : location === "csa"
        ? "CBSAs"
        : location === "climate"
        ? "Climate Zones"
        : ""
    }
      - ${
        location === "state"
          ? `${
              stateProvinceNames === undefined ||
              (stateProvinceNames && stateProvinceNames.length <= 0)
                ? "All"
                : stateProvinceNames.join(", ")
            }`
          : location === "csa"
          ? `${
              (csa_area === undefined || (csa_area && csa_area.length < 1)) &&
              (csa_city === undefined || (csa_city && csa_city.length < 1))
                ? "All"
                : (csa_area === undefined ||
                    (csa_area && csa_area.length < 1)) &&
                  csa_city &&
                  csa_city.length >= 1
                ? csa_city.join(", ")
                : csa_area &&
                  csa_area.length >= 1 &&
                  (csa_city === undefined || (csa_city && csa_city.length < 1))
                ? csa_area.join(", ")
                : (csa_city || []).concat(csa_area).join(", ")
            }`
          : location === "climate"
          ? `${
              climateZone === undefined ||
              (climateZone && climateZone.length <= 0)
                ? "All"
                : climateZone.join(", ")
            }`
          : ""
      }
    - Data Year
      - ${yearReported}
    `;

      // Temporarily save readme string with worker density included

      // readme = `

      // ** Filters applied for ${filename} **

      // - Metric
      //   - ${energyUseIntensity.find((x: any) => x.name === metric)?.value}
      // - ENERGY STAR Certified?
      //   - ${
      //     isEnergyStarCertified === 1
      //       ? "Yes"
      //       : isEnergyStarCertified === 0
      //       ? "No"
      //       : "SHOW ALL"
      //   }
      // - Year Built
      //   - ${
      //     yearBuiltGroups.length <= 0
      //       ? "Before 1946 – After 2000"
      //       : yearBuiltGroups
      //   }
      // - Weekly Hours
      //   - ${
      //     weeklyHoursGroup.length <= 0
      //       ? "<40 – Open Continuously"
      //       : weeklyHoursGroup
      //   }
      // - Worker Density
      //   - ${workerDensityGroups.length <= 0 ? "<0.2 – 3+" : workerDensityGroups},
      // - Gross Floor Area (sq. ft.)
      //   - ${
      //     (gfaGroup2s === undefined || gfaGroup2s.length < 1) &&
      //     (gfaGroups === undefined || gfaGroups.length < 1)
      //       ? "1,000 – 500,000+"
      //       : (gfaGroup2s === undefined || gfaGroup2s.length < 1) &&
      //         gfaGroups &&
      //         gfaGroups.length >= 1
      //       ? gfaGroups
      //       : gfaGroup2s &&
      //         gfaGroup2s.length >= 1 &&
      //         (gfaGroups === undefined || gfaGroups.length < 1)
      //       ? gfaGroup2s
      //       : gfaGroups + ", " + gfaGroup2s
      //   }
      // - Property Types
      //   - ${
      //     (ptCategories === undefined || ptCategories.length < 1) &&
      //     (ptSubcategories === undefined || ptSubcategories.length < 1)
      //       ? "All"
      //       : (ptCategories === undefined || ptCategories.length < 1) &&
      //         ptSubcategories &&
      //         ptSubcategories.length >= 1
      //       ? ptSubcategories
      //       : ptCategories &&
      //         ptCategories.length >= 1 &&
      //         (ptSubcategories === undefined || ptSubcategories.length < 1)
      //       ? ptCategories
      //       : ptSubcategories + ", " + ptCategories
      //   },
      // - ${
      //   location === "state"
      //     ? "States"
      //     : location === "csa"
      //     ? "CBSAs"
      //     : location === "climate"
      //     ? "Climate Zones"
      //     : ""
      // }
      //   - ${
      //     location === "state"
      //       ? `${stateProvinceNames.length <= 0 ? "All" : stateProvinceNames}`
      //       : location === "csa"
      //       ? `${
      //           (csa_area === undefined || csa_area.length < 1) &&
      //           (csa_city === undefined || csa_city.length < 1)
      //             ? "All"
      //             : (csa_area === undefined || csa_area.length < 1) &&
      //               csa_city &&
      //               csa_city.length >= 1
      //             ? csa_city
      //             : csa_area &&
      //               csa_area.length >= 1 &&
      //               (csa_city === undefined || csa_city.length < 1)
      //             ? csa_area
      //             : csa_city + ", " + csa_area
      //         }`
      //       : location === "climate"
      //       ? `${climateZone.length <= 0 ? "All" : climateZone}`
      //       : ""
      //   }
      // - Data Year
      //   - ${yearReported}
      // `;

      if (fileType !== "Excel") {
        let FileSaver = require("file-saver");
        let readMeData = new Blob([readme], {
          type: "text/plain;charset=utf-8",
        });
        FileSaver.saveAs(readMeData, readmeFileName);
      }
    }
  }

  return (
    <div className={styles.DownloadDrawer}>
      <h2>Download</h2>
      <hr />
      <fieldset
        className="file_type_radio"
        onChange={(e) => handleFileTypeChange(e)}
      >
        <h3 className={styles.downloadDrawerLabel} id="file_type-label">
          Choose your file type
        </h3>
        <p>
          Your download will include only the data you currently see on your
          screen.
        </p>
        <div
          className="usa-radio usa-radio-white"
          aria-labelledby="file_type-label"
        >
          <input
            className="usa-radio__input"
            id="file_type_excel"
            key="file_type_excel"
            type="radio"
            name="file_type"
            value="Excel"
            defaultChecked={true}
          />
          <label className="usa-radio__label" htmlFor="file_type_excel">
            Excel
          </label>
          <input
            className="usa-radio__input"
            id="file_type_csv"
            key="file_type_csv"
            type="radio"
            name="file_type"
            value="CSV"
            defaultChecked={false}
          />
          <label className="usa-radio__label" htmlFor="file_type_csv">
            CSV
          </label>
          <input
            className="usa-radio__input"
            id="file_type_json"
            key="file_type_json"
            type="radio"
            name="file_type"
            value="JSON"
            defaultChecked={false}
          />
          <label className="usa-radio__label" htmlFor="file_type_json">
            JSON
          </label>
          <div className="helper-text">
            <p>
              If you choose Excel, your chosen filters will be displayed in a
              separate sheet alongside your data.
            </p>
            <p>
              If you select CSV or JSON, your filters will download as a second
              markdown file that can be opened using a text editor or a program
              like "notepad." All downloaded files’ names will incorporate your
              chosen metric and the current date.
            </p>
          </div>
        </div>
      </fieldset>
      {(results?.getResults?.results?.length || 0) <= 1 ||
      results?.getResults?.count === "≤5" ? (
        <p className="error">
          This download will yeild no results. Please update your search.
        </p>
      ) : (
        ""
      )}
      <div className={styles.downloadButtonContainer}>
        <button
          className="usa-button usa-button-white"
          onClick={() => downloadResults()}
          disabled={
            (results?.getResults?.results?.length || 0) <= 1 ||
            results?.getResults?.count === "≤5"
              ? true
              : false
          }
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default Download;
