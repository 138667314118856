import { Tooltip } from "@mui/material";
import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import GlossaryIcon from "../../assets/icons/glossary";
import styles from "./singleSelectDropdown.module.scss";
import { useAppContext } from "../../AppProvider";

interface SingleSelectDropdownProps {
  label: any;
  data: any;
  name: any;
  setGroup1: any;
  group1: any;
  glossaryLink: any;
}
const SingleSelectDropdown: FC<SingleSelectDropdownProps> = ({
  glossaryLink,
  setGroup1,
  label,
  data,
  name,
  group1,
}) => {
  const [singleSelectIsClear, setSingleSelectIsClear] = useState(true);
  const selectedChildrenRef = useRef(0);
  const dropdownRef = useRef(null);
  const { resetFiltersToggle } = useAppContext();

  const handleStateChange = 
    (e: any, selected: any) => {
    const newSelectedChildren = e.checked
      ? selectedChildrenRef.current + 1
      : selectedChildrenRef.current - 1;

    selectedChildrenRef.current = newSelectedChildren;

    if (selected.length === 0) {
      setGroup1(undefined);
    } else {
      setGroup1(
        selected.filter((x: any) => x._depth === 0).map((x: any) => x.value)
      );
    }
    // eslint-disable-next-line
  }

  useEffect(() => {
    handleTabIndexes();
    const singleSelectInput = document.querySelector(
      `.${name} .dropdown .dropdown-trigger .tag-list .tag-item input`
    ) as any;
    singleSelectInput.type = "search";
  });

  let handleTabIndexes = () => {
    let singleSelectDropdownContent = document.getElementsByClassName(
      "dropdown-content"
    ) as any;
    let MSDCCheckboxes = document.getElementsByClassName(
      "checkbox-item"
    ) as any;
    let MSDCToggles = document.getElementsByClassName("toggle") as any;
    let searchInput = document.getElementsByClassName("search") as any;
    let searchTrigger = document.getElementById("rdts1_trigger") as any;
    searchInput.tabIndex = 0;
    searchTrigger.tabIndex = -1;
    Array.from(singleSelectDropdownContent).forEach((e: any) => {
      e.tabIndex = 0;
      e.ariaLabel = "checkbox tree";
    });
    searchInput.tabIndex = 0;
    setTimeout(function () {
      Array.from(MSDCCheckboxes).forEach((e: any) => {
        e.tabIndex = 0;
      });
    }, 5);
    Array.from(MSDCToggles).forEach((e: any) => {
      e.tabIndex = 0;
      e.ariaHidden = false;
      e.ariaLabel = "toggle checkbox group";
    });
  };

  let singleSelectDropdown = useMemo(() => {
    return (
      <DropdownTreeSelect
        onChange={handleStateChange}
        showDropdown="always"
        clearSearchOnChange={true}
        keepTreeOnSearch={true}
        keepOpenOnSelect={true}
        texts={{
          placeholder: `Search or select below`,
          label: `Search ${label}`,
        }}
        data={data}
        className={`multiSelectDropdown singleSelect ${name}`}
      />
    );
    // eslint-disable-next-line
  }, [singleSelectIsClear, resetFiltersToggle]);

  let clearSingleSelect = () => {
    setSingleSelectIsClear(!singleSelectIsClear);
    selectedChildrenRef.current = 0;
    setGroup1(undefined);
  };

  const areAnyCheckboxesChecked = () => {
    if (dropdownRef.current) {
      const checkboxes = (dropdownRef.current as HTMLElement).querySelectorAll<HTMLInputElement>('input[type="checkbox"]');
      return Array.from(checkboxes).some(checkbox => checkbox.checked);
    }
    return false;
  };

  return (
    <div className={styles.SingleSelectDropdown} ref={dropdownRef}>
      <span className="usa-label grid-row flex-justify">
        <label htmlFor={name}>{label}</label>
        {glossaryLink ? (
          <Tooltip title="Glossary (opens in new tab)">
            <a
              href={glossaryLink}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`${label} Glossary`}
              className={`usa-button usa-button--unstyled iconButton`}
            >
              <GlossaryIcon scale={1} color="#182e4e" />
            </a>
          </Tooltip>
        ) : (
          ""
        )}
      </span>
      <div>{singleSelectDropdown}</div>
      <div className="grid-row flex-justify-end clear-button">
        <button
          id={`${label}-clear`}
          className={`usa-button--unstyled step--clear-${name}`}
          disabled={!areAnyCheckboxesChecked()}
          onClick={() => clearSingleSelect()}
        >
          Clear selection
        </button>
      </div>
    </div>
  );
};

export default SingleSelectDropdown;
