import React, { FC, useEffect, useState } from "react";
import styles from "./dataExplorerPage.module.scss";
import MainContent from "../../components/mainContent/mainContent";
import { Drawer } from "@mui/material";
import CloseIcon from "../../../src/assets/icons/close";
import Help from "../../../src/components/help/help";
import Download from "../../../src/components/download/download";
import { tableJSON } from "../../assets/data/data";
import Tour from "reactour";
import FilterSidepanel from "../../components/filterSidepanel/filterSidepanel";
import PlusIcon from "../../assets/icons/plus";
import MinusIcon from "../../assets/icons/minus";
import { useAppContext, useDispatchContext } from "../../AppProvider";
import { displayByFilters } from "../../assets/data/filters";

const DataExplorerPage: FC = () => {
  const { groupBy, drawerOpen } = useAppContext();
  const dispatch = useDispatchContext();

  const [view, setView] = useState<string>("Table");
  const [chartType, setChartType] = useState<string | undefined>(undefined);
  const [isTourOpen, setIsTourOpen] = useState<boolean>(false);
  const [groupByResults, setGroupByResults] = useState<Array<string>>([]);
  const [thenByResults, setThenByResults] = useState<Array<string>>([]);
  const [readableThenBy, setReadableThenBy] = useState<Array<string>>([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [filtersVisibility, setFiltersVisibility] = useState(true);
  const [processedAllRow, setProcessedAllRow] = useState<any>({});
  const [entries, setEntries] = useState<Array<any>>([]);
  const [sortedChartData, setSortedChartData] = useState<Array<any>>([]);
  const [sortBy, setSortBy] = useState<{
    column: string;
    ascending: boolean | null;
  }>({ column: "", ascending: null });

  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 640;
      if (ismobile !== isMobile) {
        setIsMobile(ismobile);
      }
    });
  }, [isMobile]);

  let toggleFilters = () => {
    setFiltersVisibility(!filtersVisibility);
  };

  const steps = [
    {
      selector: ".step--show-me",
      content: (
        <div>
          <h3>Show me</h3>
          <p>
            Select a metric. This will determine what results display in the
            table or chart, in combination with the filters applied.
          </p>
        </div>
      ),
    },
    {
      selector: ".step--viewed-as",
      content: (
        <div>
          <h3>View as</h3>
          <p>
            Use this toggle to view results by table or chart. For the table
            select columns; for the chart select type.
          </p>
        </div>
      ),
    },
    {
      selector: ".step--filtered-by",
      content: (
        <div>
          <h3>Filtered by</h3>
          <p>
            This section contains all the filters available to specify which
            properties to include in the results.
          </p>
        </div>
      ),
    },
    {
      selector: ".step--property-type",
      content: (
        <div>
          <h3>Property type</h3>
          <p>
            Begin typing into the text box to search through options. Note that
            selecting no boxes is the same as selecting all boxes. Selecting no
            boxes means display will show all.
          </p>
        </div>
      ),
    },
    {
      selector: ".step--clear-property-type",
      content: (
        <div>
          <h3>Clear selection</h3>
          <p>Select this to clear all selections made within a filter.</p>
        </div>
      ),
    },
    {
      selector: ".step--details-panel",
      content: (
        <div>
          <h3>Details panel</h3>
          <p>
            See all the filters currently applied to produce the results in the
            table or chart.
          </p>
        </div>
      ),
      action: (node: any) => {
        if (node) {
          node.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      },
    },
    {
      selector: ".step--display-by",
      content: (
        <div>
          <h3>Display first by and Then by (optional)</h3>
          <p>Use these dropdowns to decide how to group results.</p>
        </div>
      ),
      action: (node: any) => {
        if (node) {
          node.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      },
    },
    {
      selector: ".step--glossary",
      content: (
        <div>
          <h3>Glossary icon</h3>
          <p>
            Click on this icon to open the Portfolio Manager glossary for full
            definitions of terms used in this tool.
          </p>
        </div>
      ),
      action: (node: any) => {
        if (node) {
          node.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      },
    },
    {
      selector: ".step--download",
      content: (
        <div>
          <h3>Download icon</h3>
          <p>
            Click on this icon to see options for downloading the data used to
            generate the current query results.
          </p>
        </div>
      ),
      action: (node: any) => {
        if (node) {
          node.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      },
    },
    {
      selector: ".step--help",
      content: (
        <div>
          <h3>Help icon</h3>
          <p>
            Click on the question mark icon to take the tour or get more
            information about Portfolio Manager Data Explorer.
          </p>
        </div>
      ),
      action: (node: any) => {
        if (node) {
          node.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      },
    },
  ];

  let handleTourClose = () => {
    setIsTourOpen(false);
  };

  // hide columns on load if checkboxes are false, update with checkbox filters
  let handleColumnChange = (object: any) => {
    switch (object.currentTarget.value) {
      case "Mean":
        dispatch({
          type: "SET_WITH_MEAN",
          payload: object.currentTarget.checked,
        });
        break;
      case "Median":
        dispatch({
          type: "SET_WITH_MEDIAN",
          payload: object.currentTarget.checked,
        });
        break;
      case "5th Percentile":
        dispatch({
          type: "SET_WITH_FIFTH_PERCENTILE",
          payload: object.currentTarget.checked,
        });
        break;
      case "25th Percentile":
        dispatch({
          type: "SET_WITH_TWENTY_FIFTH_PERCENTILE",
          payload: object.currentTarget.checked,
        });
        break;
      case "75th Percentile":
        dispatch({
          type: "SET_WITH_SEVENTY_FIFTH_PERCENTILE",
          payload: object.currentTarget.checked,
        });
        break;
      case "95th Percentile":
        dispatch({
          type: "SET_WITH_NINETY_FIFTH_PERCENTILE",
          payload: object.currentTarget.checked,
        });
        break;
    }

    setSortBy({ column: "", ascending: null });
  };

  const getHeadings = () => {
    return Object.keys(tableJSON[0]);
  };

  const readableGroupBy = displayByFilters.find(
    (x) => x.value === groupBy
  )?.name;

  return (
    <div
      className={`${styles.DataExplorerPage} grid-row`}
      data-testid="DataExplorerPage"
    >
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={handleTourClose}
        accentColor="#009CA2"
        rounded={5}
        closeButtonAriaLabel={"Close tour"}
        className="tour"
        showNavigation={false}
        prevButton={"Back"}
        nextButton={"Next"}
        startAt={0}
        lastStepNextButton={"Done!"}
      />
      <Drawer
        anchor="right"
        open={drawerOpen.open}
        variant="temporary"
        onClose={() =>
          dispatch({
            type: "SET_DRAWER_OPEN",
            payload: { open: false, drawerView: "" },
          })
        }
        className="Drawer"
        PaperProps={{
          sx: {
            backgroundColor:
              drawerOpen.drawerView === "Help"
                ? "#009CA2"
                : drawerOpen.drawerView === "Download"
                ? "#007DA2"
                : "",
            color: "#fff",
            padding: "20px 40px",
            width: "485px",
          },
        }}
      >
        <span className="grid-row flex-justify-end">
          <button
            className="closeButton"
            onClick={() =>
              dispatch({
                type: "SET_DRAWER_OPEN",
                payload: { open: false, drawerView: "" },
              })
            }
            aria-label={`Close Drawer`}
          >
            <CloseIcon scale={0.5} color="#fff" />
          </button>
        </span>
        {drawerOpen.drawerView === "Help" ? (
          <Help
            isMobile={isMobile}
            setFiltersVisibility={setFiltersVisibility}
            setIsTourOpen={setIsTourOpen}
          />
        ) : (
          <Download
            readableGroupBy={readableGroupBy}
            readableThenBy={readableThenBy}
            entries={entries}
            processedAllRow={processedAllRow}
            sortedChartData={sortedChartData}
            view={view}
          />
        )}
      </Drawer>
      <div
        className={`${
          isTourOpen ? styles.disableScroll : styles.filterWrapper
        } desktop:grid-col-2 tablet:grid-col-4`}
      >
        <button
          className={`${
            isMobile ? "buttonFlex" : "buttonNone"
          } usa-button filters-button`}
          onClick={toggleFilters}
        >
          <i>
            {!filtersVisibility ? (
              <PlusIcon scale={1} color="#fff" />
            ) : (
              <MinusIcon scale={1} color="#fff" />
            )}
          </i>
          Filters
        </button>
        <div
          className={
            !isMobile
              ? "displayBlock"
              : filtersVisibility
              ? "displayBlock"
              : "displayNone"
          }
        >
          <FilterSidepanel
            view={view}
            setView={setView}
            setChartType={setChartType}
            handleColumnChange={handleColumnChange}
          />
        </div>
      </div>
      <div
        className={`${styles.mainContentWrapper} desktop:grid-col-10 tablet:grid-col-8`}
      >
        <MainContent
          isMobile={isMobile}
          setReadableThenBy={setReadableThenBy}
          readableThenBy={readableThenBy}
          readableGroupBy={readableGroupBy}
          groupByResults={groupByResults}
          thenByResults={thenByResults}
          setGroupByResults={setGroupByResults}
          setThenByResults={setThenByResults}
          chartType={chartType}
          view={view}
          theadData={getHeadings()}
          processedAllRow={processedAllRow}
          setProcessedAllRow={setProcessedAllRow}
          entries={entries}
          setEntries={setEntries}
          setSortedChartData={setSortedChartData}
          setSortBy={setSortBy}
          sortBy={sortBy}
        />
      </div>
    </div>
  );
};

export default DataExplorerPage;
