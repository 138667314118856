import { gql } from "@apollo/client";

export const GET_RESULTS = gql`
  query GetResults(
    $metric: Metric!
    $yearReported: Int!
    $groupBy: Grouping!
    $thenBy: Grouping
    $stateProvinceNames: [String]
    $counties: [String]
    $yearBuiltGroups: [String]
    $gfaGroups: [String]
    $gfaGroup2s: [String]
    $csa_city: [String]
    $csa_area: [String]
    $climateZone: [String]
    $isEnergyStarCertified: Boolean
    $workerDensityGroups: [String]
    $weeklyHoursGroup: [String]
    $ptCategories: [String]
    $ptSubcategories: [String]
    $withMean: Boolean!
    $withMedian: Boolean!
    $withFifthPercentile: Boolean!
    $withTwentyFifthPercentile: Boolean!
    $withSeventyFifthPercentile: Boolean!
    $withNinetyFifthPercentile: Boolean!
  ) {
    getResults(
      metric: $metric
      yearReported: $yearReported
      groupBy: $groupBy
      thenBy: $thenBy
      stateProvinceNames: $stateProvinceNames
      counties: $counties
      yearBuiltGroups: $yearBuiltGroups
      gfaGroups: $gfaGroups
      gfaGroup2s: $gfaGroup2s
      csa_city: $csa_city
      csa_area: $csa_area
      climateZone: $climateZone
      isEnergyStarCertified: $isEnergyStarCertified
      workerDensityGroups: $workerDensityGroups
      weeklyHoursGroup: $weeklyHoursGroup
      ptCategories: $ptCategories
      ptSubcategories: $ptSubcategories
      withMean: $withMean
      withMedian: $withMedian
      withFifthPercentile: $withFifthPercentile
      withTwentyFifthPercentile: $withTwentyFifthPercentile
      withSeventyFifthPercentile: $withSeventyFifthPercentile
      withNinetyFifthPercentile: $withNinetyFifthPercentile
    ) {
      results {
        group
        rowCount
        median @include(if: $withMedian)
        mean @include(if: $withMean)
        fifthPercentile @include(if: $withFifthPercentile)
        twentyFifthPercentile @include(if: $withTwentyFifthPercentile)
        seventyFifthPercentile @include(if: $withSeventyFifthPercentile)
        ninetyFifthPercentile @include(if: $withNinetyFifthPercentile)
      }
      count
    }
  }
`;