import { ApolloQueryResult, useQuery } from "@apollo/client";
import React, { ReactNode, createContext, useContext, useReducer } from "react";
import { GET_TRENDS } from "./graphql/queries/trends";
import { GET_FILTERS } from "./graphql/queries/filters";
import { GET_RESULTS } from "./graphql/queries/results";

interface AppState {
  isTrendsShown: boolean;
  withMean: boolean;
  withMedian: boolean;
  withFifthPercentile: boolean;
  withTwentyFifthPercentile: boolean;
  withSeventyFifthPercentile: boolean;
  withNinetyFifthPercentile: boolean;
  resetFiltersToggle: boolean;
  metric: string;
  isEnergyStarCertified: boolean | undefined;
  yearReported: number;
  yearBuiltGroups: Array<any> | undefined;
  stateProvinceNames: Array<any> | undefined;
  gfaGroups: Array<any> | undefined;
  gfaGroup2s: Array<any> | undefined;
  groupBy: any | undefined;
  thenBy: any | undefined;
  counties: any | undefined;
  weeklyHoursGroup: Array<any> | undefined;
  workerDensityGroups: Array<any> | undefined;
  ptSubcategories: Array<any> | undefined;
  ptCategories: Array<any> | undefined;
  csa_city: Array<any> | undefined;
  csa_area: Array<any> | undefined;
  climateZone: Array<any> | undefined;
  drawerOpen: {
    open: boolean;
    drawerView: string;
  };
  location: string;
}

const initialState: AppState = {
  isTrendsShown: false,
  withMean: false,
  withMedian: true,
  withFifthPercentile: false,
  withTwentyFifthPercentile: true,
  withSeventyFifthPercentile: true,
  withNinetyFifthPercentile: false,
  resetFiltersToggle: false,
  metric: "siteEui",
  isEnergyStarCertified: undefined,
  yearReported: 2022,
  yearBuiltGroups: [],
  stateProvinceNames: [],
  gfaGroups: [],
  gfaGroup2s: [],
  groupBy: "ptSubcategory",
  thenBy: undefined,
  counties: [],
  weeklyHoursGroup: [],
  workerDensityGroups: [],
  ptSubcategories: [],
  ptCategories: [],
  csa_city: [],
  csa_area: [],
  climateZone: [],
  drawerOpen: {
    open: false,
    drawerView: "",
  },
  location: "state",
};

interface QueryResult<TData> {
  data?: TData;
  error?: any;
  loading: boolean;
}

type AppAction =
  // | { type: "TOGGLE_TRENDS" }
  | { type: "SET_IS_TRENDS_SHOWN"; payload: boolean }
  | { type: "SET_WITH_MEAN"; payload: boolean }
  | { type: "SET_WITH_MEDIAN"; payload: boolean }
  | { type: "SET_WITH_FIFTH_PERCENTILE"; payload: boolean }
  | { type: "SET_WITH_TWENTY_FIFTH_PERCENTILE"; payload: boolean }
  | { type: "SET_WITH_SEVENTY_FIFTH_PERCENTILE"; payload: boolean }
  | { type: "SET_WITH_NINETY_FIFTH_PERCENTILE"; payload: boolean }
  | { type: "SET_CHART_TYPE_PLOT" }
  | { type: "SET_CHART_TYPE_BAR" }
  | { type: "SET_CHART_TYPE_TRENDS" }
  | { type: "RESET_FILTERS" }
  | { type: "SET_METRIC"; payload: string }
  | { type: "SET_IS_ENERGY_STAR_CERTIFIED"; payload: boolean | undefined }
  | { type: "SET_YEAR_REPORTED"; payload: number }
  | { type: "SET_YEAR_BUILT_GROUPS"; payload: Array<any> | undefined }
  | { type: "SET_STATE_PROVINCE_NAMES"; payload: Array<any> | undefined }
  | { type: "SET_GFA_GROUPS"; payload: Array<any> | undefined }
  | { type: "SET_GFA_GROUP2S"; payload: Array<any> | undefined }
  | { type: "SET_GROUP_BY"; payload: any | undefined }
  | { type: "SET_THEN_BY"; payload: any | undefined }
  | { type: "SET_COUNTIES"; payload: Array<any> | undefined }
  | { type: "SET_WEEKLY_HOURS_GROUP"; payload: Array<any> | undefined }
  | { type: "SET_WORKER_DENSITY_GROUPS"; payload: Array<any> | undefined }
  | { type: "SET_PT_SUBCATEGORIES"; payload: Array<any> | undefined }
  | { type: "SET_PT_CATEGORIES"; payload: Array<any> | undefined }
  | { type: "SET_CSA_CITY"; payload: Array<any> | undefined }
  | { type: "SET_CSA_AREA"; payload: Array<any> | undefined }
  | { type: "SET_CLIMATE_ZONE"; payload: Array<any> | undefined }
  | { type: "SET_DRAWER_OPEN"; payload: { open: boolean; drawerView: string } }
  | { type: "SET_LOCATION"; payload: string };

const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    // case "TOGGLE_TRENDS":
    //   return { ...state, isTrendsShown: !state.isTrendsShown };
    case "SET_IS_TRENDS_SHOWN":
      return { ...state, isTrendsShown: action.payload };
    case "SET_WITH_MEAN":
      return { ...state, withMean: action.payload };
    case "SET_WITH_MEDIAN":
      return { ...state, withMedian: action.payload };
    case "SET_WITH_FIFTH_PERCENTILE":
      return { ...state, withFifthPercentile: action.payload };
    case "SET_WITH_TWENTY_FIFTH_PERCENTILE":
      return { ...state, withTwentyFifthPercentile: action.payload };
    case "SET_WITH_SEVENTY_FIFTH_PERCENTILE":
      return { ...state, withSeventyFifthPercentile: action.payload };
    case "SET_WITH_NINETY_FIFTH_PERCENTILE":
      return { ...state, withNinetyFifthPercentile: action.payload };
    case "SET_CHART_TYPE_PLOT":
      return {
        ...state,
        isTrendsShown: false,
        withMedian: true,
        withMean: true,
        withSeventyFifthPercentile: true,
        withTwentyFifthPercentile: true,
        withFifthPercentile: true,
        withNinetyFifthPercentile: true,
      };
    case "SET_CHART_TYPE_BAR":
      return {
        ...state,
        isTrendsShown: false,
        withMedian: true,
        withMean: false,
        withSeventyFifthPercentile: false,
        withTwentyFifthPercentile: false,
        withFifthPercentile: false,
        withNinetyFifthPercentile: false,
      };
    case "SET_CHART_TYPE_TRENDS":
      return { ...state, isTrendsShown: true };
    case "RESET_FILTERS":
      return {
        ...state,
        resetFiltersToggle: !state.resetFiltersToggle,
        yearBuiltGroups: [],
        stateProvinceNames: [],
        gfaGroups: [],
        gfaGroup2s: [],
        counties: [],
        weeklyHoursGroup: [],
        workerDensityGroups: [],
        ptSubcategories: [],
        ptCategories: [],
        csa_city: [],
        csa_area: [],
        climateZone: [],
        yearReported: 2022,
        isEnergyStarCertified: undefined,
        location: "state",
      };
    case "SET_METRIC":
      return { ...state, metric: action.payload };
    case "SET_IS_ENERGY_STAR_CERTIFIED":
      return { ...state, isEnergyStarCertified: action.payload };
    case "SET_YEAR_REPORTED":
      return { ...state, yearReported: action.payload };
    case "SET_YEAR_BUILT_GROUPS":
      return { ...state, yearBuiltGroups: action.payload };
    case "SET_STATE_PROVINCE_NAMES":
      return { ...state, stateProvinceNames: action.payload };
    case "SET_GFA_GROUPS":
      return { ...state, gfaGroups: action.payload };
    case "SET_GFA_GROUP2S":
      return { ...state, gfaGroup2s: action.payload };
    case "SET_GROUP_BY":
      if (action.payload === "csa_city" && state.thenBy !== undefined) {
        return { ...state, groupBy: action.payload, thenBy: undefined };
      }
      return { ...state, groupBy: action.payload };
    case "SET_THEN_BY":
      return { ...state, thenBy: action.payload };
    case "SET_COUNTIES":
      return { ...state, counties: action.payload };
    case "SET_WEEKLY_HOURS_GROUP":
      return { ...state, weeklyHoursGroup: action.payload };
    case "SET_WORKER_DENSITY_GROUPS":
      return { ...state, workerDensityGroups: action.payload };
    case "SET_PT_SUBCATEGORIES":
      return { ...state, ptSubcategories: action.payload };
    case "SET_PT_CATEGORIES":
      return { ...state, ptCategories: action.payload };
    case "SET_CSA_CITY":
      return { ...state, csa_city: action.payload };
    case "SET_CSA_AREA":
      return { ...state, csa_area: action.payload };
    case "SET_CLIMATE_ZONE":
      return { ...state, climateZone: action.payload };
    case "SET_DRAWER_OPEN":
      return { ...state, drawerOpen: action.payload };
    case "SET_LOCATION":
      return { ...state, location: action.payload };
    default:
      return state;
  }
};

interface QueryContextValue<TData> {
  queryData: QueryResult<TData>;
  refetch: () => Promise<ApolloQueryResult<TData>>;
}

const QueryContext = createContext<{
  results: QueryContextValue<any>;
  trends: QueryContextValue<any>;
  filters: QueryContextValue<any>;
}>({
  results: {
    queryData: { loading: true },
    refetch: () => Promise.resolve({} as ApolloQueryResult<any>),
  },
  trends: {
    queryData: { loading: true },
    refetch: () => Promise.resolve({} as ApolloQueryResult<any>),
  },
  filters: {
    queryData: { loading: true },
    refetch: () => Promise.resolve({} as ApolloQueryResult<any>),
  },
});

const AppContext = createContext<AppState | undefined>(undefined);

const DispatchContext = createContext<React.Dispatch<AppAction> | undefined>(
  undefined
);

export const AppProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const resultsVariables = {
    groupBy: state.groupBy,
    metric: state.metric,
    yearReported: state.yearReported,
    thenBy: state.thenBy,
    stateProvinceNames: state.stateProvinceNames,
    counties: state.counties,
    yearBuiltGroups: state.yearBuiltGroups,
    gfaGroups: state.gfaGroups,
    gfaGroup2s: state.gfaGroup2s,
    isEnergyStarCertified: state.isEnergyStarCertified,
    workerDensityGroups: state.workerDensityGroups,
    weeklyHoursGroup: state.weeklyHoursGroup,
    climateZone: state.climateZone,
    csa_city: state.csa_city,
    csa_area: state.csa_area,
    ptSubcategories: state.ptSubcategories,
    ptCategories: state.ptCategories,
    withMean: state.withMean,
    withMedian: state.withMedian,
    withFifthPercentile: state.withFifthPercentile,
    withTwentyFifthPercentile: state.withTwentyFifthPercentile,
    withSeventyFifthPercentile: state.withSeventyFifthPercentile,
    withNinetyFifthPercentile: state.withNinetyFifthPercentile,
  };

  const {
    data: resultsData,
    error: resultsError,
    loading: resultsLoading,
    refetch: resultsRefetch,
  } = useQuery(GET_RESULTS, {
    variables: resultsVariables,
    skip: state.isTrendsShown,
  });
  const resultsValue = {
    queryData: {
      data: resultsData,
      error: resultsError,
      loading: resultsLoading,
    },
    refetch: resultsRefetch,
  };

  const trendsVariables = {
    groupBy: state.groupBy,
    metric: state.metric,
    stateProvinceNames: state.stateProvinceNames,
    counties: state.counties,
    yearBuiltGroups: state.yearBuiltGroups,
    gfaGroups: state.gfaGroups,
    gfaGroup2s: state.gfaGroup2s,
    isEnergyStarCertified: state.isEnergyStarCertified,
    workerDensityGroups: state.workerDensityGroups,
    weeklyHoursGroup: state.weeklyHoursGroup,
    climateZone: state.climateZone,
    csa_city: state.csa_city,
    csa_area: state.csa_area,
    ptSubcategories: state.ptSubcategories,
    ptCategories: state.ptCategories,
  };
  const {
    data: trendsData,
    error: trendsError,
    loading: trendsLoading,
    refetch: trendsRefetch,
  } = useQuery(GET_TRENDS, {
    variables: trendsVariables,
    skip: !state.isTrendsShown,
  });
  const trendsValue = {
    queryData: { data: trendsData, error: trendsError, loading: trendsLoading },
    refetch: trendsRefetch,
  };

  const {
    data: filtersData,
    error: filtersError,
    loading: filtersLoading,
    refetch: filtersRefetch,
  } = useQuery(GET_FILTERS);
  const filtersValue = {
    queryData: {
      data: filtersData,
      error: filtersError,
      loading: filtersLoading,
    },
    refetch: filtersRefetch,
  };

  const queryContextValue = {
    results: resultsValue,
    trends: trendsValue,
    filters: filtersValue,
  };

  return (
    <AppContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <QueryContext.Provider value={queryContextValue}>
          {children}
        </QueryContext.Provider>
      </DispatchContext.Provider>
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

export const useDispatchContext = () => {
  const context = useContext(DispatchContext);
  if (!context) {
    throw new Error("useDispatchContext must be used within an AppProvider");
  }
  return context;
};

export const useQueryContext = () => {
  const context = useContext(QueryContext);
  if (!context) {
    throw new Error("useQueryContext must be used within an AppProvider");
  }
  return context;
};

export const useResultsQuery = <TData = any,>(): QueryResult<TData> => {
  const context = useQueryContext();
  return context.results.queryData;
};

export const useTrendsQuery = <TData = any,>(): QueryResult<TData> => {
  const context = useQueryContext();
  return context.trends.queryData;
};

export const useFiltersQuery = <TData = any,>(): QueryResult<TData> => {
  const context = useQueryContext();
  return context.filters.queryData;
};
